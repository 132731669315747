import React from 'react'
import {connect} from 'react-redux'
import {Description, getDiffFromOptions, withFormSelectors} from 'employees/form/common'
import {HandicapType} from 'FormFields'
import {Section, RadioField, TextField} from 'jbc-front/components/Form'
import {DependentsInSingle} from 'employees/form/DependentsInSingle'
import {isAdminSelector} from 'utils'

export const widowTypes = [
  {value: 'none', label: '対象外'},
  {value: 'normal', label: '寡婦（夫）'},
  {value: 'special', label: '特別寡婦'},
]

export const singleParentWidowTypes = [
  {value: 'none', label: '対象外'},
  {value: 'single_parent', label: 'ひとり親'},
  {value: 'normal', label: '寡婦'},
]

export const workingStudentTypes = [
  {value: 'none', label: '対象外'},
  {value: 'working_student', label: '勤労学生'},
]

export const PersonalTypeDependent = ({
  isAdmin,
  handicapType,
  widowType,
  singleParentWidowType,
  workingStudentType,
  dependentsInSingleType,
  diff = {},
}) => (
  <div>
    <HandicapType handicapType={handicapType} diff={diff} />
    <RadioField
      name="working_student_type"
      label="勤労学生区分"
      options={workingStudentTypes}
      note={
        <p>
          納税者自身が学生の場合、住民税や所得税の負担金額が少なくなります。副業などで他に収入がない場合、収入金額130万円以下であれば適用されます。詳しくは
          <a
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/115001907472"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </p>
      }
      diff={getDiffFromOptions(workingStudentTypes, diff?.working_student_type)}
    />
    {workingStudentType && workingStudentType !== 'none' && (
      <TextField
        name="working_student_detail"
        label="詳細情報"
        note="学校名と入学年月日を入力してください"
        diff={diff.working_student_detail}
      />
    )}
    <RadioField
      name="single_parent_widow_type"
      label="ひとり親・寡婦区分"
      options={singleParentWidowTypes}
      note={
        <p>
          性別にかかわらず、配偶者がおらず同一生計の子がいる場合は「ひとり親」に該当し、住民税や所得税の負担金額が少なくなります。詳しくは
          <a
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/115001900431"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </p>
      }
      diff={getDiffFromOptions(widowTypes, diff?.single_parent_widow_type)}
    />
    {singleParentWidowType && singleParentWidowType !== 'none' && (
      <TextField
        name="single_parent_widow_detail"
        label="詳細情報"
        note="「死別」「離婚」「生死不明」"
        diff={diff.single_parent_widow_detail}
      />
    )}
    {isAdmin && (
      <RadioField
        name="widow_type"
        label="寡婦（夫）区分（2020年以前）"
        options={widowTypes}
        note={
          <p>
            夫と死別または離婚してその後再婚していない女性を寡婦といい、住民税や所得税の負担金額が少なくなります。（男性の場合を寡夫と言います）詳しくは
            <a
              href="https://jobcan-lms.zendesk.com/hc/ja/articles/115001900431"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </p>
        }
        diff={getDiffFromOptions(widowTypes, diff?.widow_type)}
      />
    )}
    {isAdmin && widowType && widowType !== 'none' && (
      <TextField name="widow_detail" label="詳細情報" note="「死別」「離婚」「生死不明」" diff={diff.widow_detail} />
    )}
    {isAdmin && <DependentsInSingle dependentsInSingleType={dependentsInSingleType} diff={diff} />}
  </div>
)

const PersonalType = ({
  isAdmin,
  handicapType,
  widowType,
  singleParentWidowType,
  workingStudentType,
  dependentsInSingleType,
  diff,
  description,
  description_color_by_rgb,
}) => (
  <Section title="税区分情報">
    <Description
      {...{
        description,
        description_color_by_rgb,
      }}
    />
    <PersonalTypeDependent
      {...{isAdmin, handicapType, widowType, singleParentWidowType, workingStudentType, dependentsInSingleType, diff}}
    />
  </Section>
)

export default PersonalType
  |> connect((state, {selector}) => ({
    isAdmin: isAdminSelector(state),
    nationalTypeValue: selector(state, 'national_type'),
    handicapType: selector(state, 'handicap_type'),
    widowType: selector(state, 'widow_type'),
    singleParentWidowType: selector(state, 'single_parent_widow_type'),
    workingStudentType: selector(state, 'working_student_type'),
    dependentsInSingleType: selector(state, 'dependents_in_single_type'),
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors
