import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION, EMPLOYMENT, OTHER} from 'procedureStatuses/reportList/common'
import ListGroup from 'jbc-front/components/ListGroup'

export const getReportList = function* ({
  procedureStatus,
  healthInsuranceType,
  healthInsuranceJoined,
  welfarePensionInsuranceJoined,
  employmentInsuranceJoined,
}) {
  const employee = procedureStatus.employee
  if (
    (healthInsuranceType === 'kyokai' && (healthInsuranceJoined || welfarePensionInsuranceJoined)) ||
    (healthInsuranceType === 'other' && welfarePensionInsuranceJoined)
  ) {
    yield {
      num: 'r7',
      title: '健康保険・厚生年金保険被保険者資格喪失届',
      editable: true,
      type: HEALTH_AND_WELFARE_PENSION,
    }
  }
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
      if (healthInsuranceJoined) {
        yield {
          num: 'r46',
          title: '健康保険被保険者証回収不能届',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
    } else if (healthInsuranceType === 'its') {
      if (welfarePensionInsuranceJoined) {
        yield {
          num: 'r8',
          title: '健康保険・厚生年金保険被保険者資格喪失届',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
      if (healthInsuranceJoined) {
        yield {
          num: 'r140',
          title: '健康保険被保険者証回収不能',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
        yield {
          num: 'r47',
          title: '健康保険被保険者証滅失届',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
    }
  }
  if (employmentInsuranceJoined) {
    yield {
      num: 'r58',
      title: '雇用保険被保険者資格喪失届',
      editable: true,
      type: EMPLOYMENT,
    }
    yield {
      num: 'r60',
      title: '雇用保険被保険者離職証明書',
      editable: true,
      type: EMPLOYMENT,
    }
  } else if (employee.national_type === 'foreigner') {
    yield {
      num: 'r71',
      title: '外国人雇用状況届出書',
      editable: true,
      type: OTHER,
    }
  }

  yield {
    num: 'r128',
    title: '労働者名簿',
    editable: false,
    type: OTHER,
  }
  yield {
    num: 'r131',
    title: '退職証明書',
    editable: true,
    type: OTHER,
  }
}

const ProcedureInfo = ({procedureStatus}) =>
  procedureStatus.resign_employee_input_datum ? (
    <ListGroup>
      <ListGroup.Title>従業員入力</ListGroup.Title>
      <ListGroup.Item as={Link} to={`/resign/confirm/${procedureStatus.id}`}>
        従業員入力確認
      </ListGroup.Item>
    </ListGroup>
  ) : null
export default {getReportList, ProcedureInfo}
