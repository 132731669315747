import React from 'react'
import Master from 'Master'
import BasicInformation from 'employees/show/BasicInformation'
import EmploymentInformation from 'employees/show/EmploymentInformation'
import RemunerationInformation from 'employees/show/RemunerationInformation'
import Marital from 'employees/show/Marital'
import PersonalType from 'employees/show/PersonalType'
import HealthAndWelfarePensionInformation from 'employees/show/HealthAndWelfarePensionInformation'
import EmploymentInsuranceInformation from 'employees/show/EmploymentInsuranceInformation'
import EmployeeContactInformation from 'employees/show/EmployeeContactInformation'
import EmployeeProject from 'employees/show/EmployeeProject'
import EmployeeSkill from 'employees/show/EmployeeSkill'
import EmployeeQualification from 'employees/show/EmployeeQualification'
import EmployeeLanguage from 'employees/show/EmployeeLanguage'
import EmployeeWorkHistory from 'employees/show/EmployeeWorkHistory'
import EmployeeEducationBackground from 'employees/show/EmployeeEducationBackground'
import EmployeeTag from 'employees/show/EmployeeTag'
import CustomFields from 'employees/show/CustomFields'
import {AnchorTarget} from 'jbc-front/components/ScrollSpy'
import {sortFieldGroupsAll} from 'utils'

const masters = ['nations', 'visaTypes', 'languages', 'languageLevels', 'schoolTypes']

const Infos = ({yearStyle, editLink, employeeFieldGroups, fieldGroups}) => {
  const fieldGroupsAll = employeeFieldGroups && fieldGroups && sortFieldGroupsAll(employeeFieldGroups, fieldGroups)

  return (
    <>
      <Master masters={masters} />
      {fieldGroupsAll &&
        fieldGroupsAll.map((group, index) => {
          switch (group.code) {
            case 'basic_infomation':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="BasicInfomation" />
                  <BasicInformation yearStyle={yearStyle} editLink={editLink} />
                </React.Fragment>
              )
            case 'employment_infomation':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmploymentInfomation" />
                  <EmploymentInformation yearStyle={yearStyle} editLink={editLink} />
                </React.Fragment>
              )
            case 'remuneration_infomation':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="RemunerationInfomation" />
                  <RemunerationInformation editLink={editLink} canEdit isAdmin />
                </React.Fragment>
              )
            case 'marital':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="Marital" />
                  <Marital editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'personal_type':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="PersonalType" />
                  <PersonalType editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'health_insurance_infomation':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="HealthInsuranceInfomation" />
                  <HealthAndWelfarePensionInformation yearStyle={yearStyle} editLink={editLink} />
                </React.Fragment>
              )
            case 'employment_insurance_infomation':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmploymentInsuranceInfomation" />
                  <EmploymentInsuranceInformation yearStyle={yearStyle} editLink={editLink} />
                </React.Fragment>
              )
            case 'employee_contact_information':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeContactInformation" />
                  <EmployeeContactInformation editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_project':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeProject" />
                  <EmployeeProject yearStyle={yearStyle} editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_skill':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeSkill" />
                  <EmployeeSkill yearStyle={yearStyle} editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_qualification':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeQualification" />
                  <EmployeeQualification yearStyle={yearStyle} editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_language':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeLanguage" />
                  <EmployeeLanguage yearStyle={yearStyle} editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_work_history':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeWorkHistory" />
                  <EmployeeWorkHistory yearStyle={yearStyle} editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_education_background':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeEducationBackground" />
                  <EmployeeEducationBackground yearStyle={yearStyle} editLink={editLink} canEdit />
                </React.Fragment>
              )
            case 'employee_tag':
              return (
                <React.Fragment key={index}>
                  <AnchorTarget name="EmployeeTag" />
                  <EmployeeTag editLink={editLink} canEdit />
                </React.Fragment>
              )
            case undefined:
              return group.key === 'custom_employee_field_group' ? (
                <CustomFields key={index} group={group} addAnchorTarget yearStyle={yearStyle} editLink={editLink} />
              ) : null
            default:
              return null
          }
        })}
    </>
  )
}

export default Infos
