import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect} from 'react-router'
import {push} from 'connected-react-router'
import {gql} from '@apollo/client'
import _ from 'lodash'
import ActionButton from 'jbc-front/components/ActionButton'
import Button from 'jbc-front/components/Button'
import {Download, Print} from 'jbc-front/components/icons'
import api from 'api'
import LoadingPage from 'components/LoadingPage'
import {useMutation, useQuery} from 'components/Graphql'
import styles from 'documents/Show.scss'
import {CommonTitle} from 'documents/show/CommonTitle'
import {DOCUMENT_FRAGMENT} from 'documents/show/Query'
import PreviewContainer from 'documents/PreviewContainer'
import Delete from 'documents/Delete'
import DynamicDocumentAsyncStatus from 'documents/show/DynamicDocumentAsyncStatus'
import DocumentContractRequestAsyncStatus from './show/DocumentContractRequestAsyncStatus'
import {FloatingButton} from 'components/FloatingButton'
import {generateDynamicPath, WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const DOCUMENT = gql`
  query document($id: ID!) {
    client {
      id
      document(id: $id) {
        ...DocumentFields
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`

const DOCUMENT_WITH_DIRTY_FLAG = gql`
  query documentWithDirtyFlag($id: ID!) {
    client {
      id
      document(id: $id) {
        id
        dynamicDocument {
          id
          relatedRecordsDirty
        }
      }
    }
  }
`

const UPDATE_DOCUMENT_VISIBILITY = gql`
  mutation updateDocumentVisibility($input: UpdateDocumentVisibilityInput!) {
    updateDocumentVisibility(input: $input) {
      document {
        id
        visibility
      }
    }
  }
`

const UPDATE_DOCUMENT_VARIABLE_SNAPSHOT = gql`
  mutation updateDocumentVariableSnapshot($input: UpdateDocumentVariableSnapshotInput!) {
    updateDocumentVariableSnapshot(input: $input) {
      document {
        ...DocumentFields
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`

const allowedPlanForSendContract = 'paid_plan'

const isNotAllowedUpdateDynamicDocument = (dynamicDocument) =>
  (dynamicDocument.status === 'waiting' || dynamicDocument.status === 'in_progress') &&
  dynamicDocument.shouldUpdateVariableSnapshot

const isNotAllowedSendContract = (document) => {
  if (!document.dynamicDocument.downloadable) {
    return '書類の作成・更新中は送信できません。'
  }

  if (!document.documentEmployeesCount) {
    return '送信先の従業員が存在しません。'
  }

  if (!document.existContractTargets) {
    return 'メールアドレスが設定されている従業員が存在しません。'
  }

  if (document.contractSending || document.contractSent) {
    return '電子契約は送信済です。'
  }
}

const DisabledReason = ({children}) => (
  <div className={styles.disabledReason}>
    <div className={styles.disabledReasonContent}>{children}</div>
  </div>
)

const visibilityOptions = [
  {value: 'private', label: '自分のみ'},
  {value: 'all_admins', label: '管理者全体'},
]

const Visibility = ({document}) => {
  const [updateDocumentVisibility] = useMutation(UPDATE_DOCUMENT_VISIBILITY)
  const handleChange = async (event) => {
    await updateDocumentVisibility({
      variables: {input: {id: document.id, visibility: event.target.value}},
      optimisticResponse: {
        updateDocumentVisibility: {
          __typename: 'UpdateDocumentVisibilityPayload',
          document: {
            id: document.id,
            visibility: event.target.value,
            __typename: 'Document',
          },
        },
      },
    })
  }

  return (
    <div className={styles.form}>
      <div className={styles.formRow}>
        <div className={styles.formRowHeader}>閲覧範囲：</div>
        {visibilityOptions.map(({value, label}) => {
          const disabled = isNotAllowedUpdateDynamicDocument(document.dynamicDocument)
          return (
            <div className={styles.visibilityInputLabel} key={value}>
              <label>
                <input
                  type="radio"
                  value={value}
                  checked={value === document.visibility}
                  disabled={disabled}
                  className="m-radio-input"
                  onChange={handleChange}
                />
                <span className="m-radio-parts">{label}</span>
                {disabled && <DisabledReason>書類の作成・更新中は削除できません。</DisabledReason>}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const RelatedRecordsDirtyAlert = ({document: {id}}) => {
  const {data} = useQuery(DOCUMENT_WITH_DIRTY_FLAG, {fetchPolicy: 'network-only', variables: {id}})
  const [updateDocumentVariableSnapshot, {loading: loadingMutation}] = useMutation(UPDATE_DOCUMENT_VARIABLE_SNAPSHOT)
  if (data?.client?.document?.dynamicDocument?.relatedRecordsDirty) {
    return (
      <div className={styles.relatedRecordsDirtyAlert}>
        書類の情報に更新があります
        <Button
          className="u-mt10"
          disabled={loadingMutation}
          onClick={async () => {
            await updateDocumentVariableSnapshot({variables: {input: {id}}})
          }}
        >
          情報更新を反映する
        </Button>
      </div>
    )
  }
  return null
}

const Preview = ({document}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [previewDocument, setPreviewDocument] = useState()

  useEffect(() => {
    if (document.dynamicDocument.previewable) {
      api
        .createWithAuth(token)
        .dynamicDocuments.getPdf(document.id, {preview: true})
        .then((pdf) => setPreviewDocument(pdf))
        .catch((err) => dispatch(asyncError(err)))
    } else {
      setPreviewDocument(null)
    }
  }, [document.dynamicDocument.previewable])

  const urlPrefix = `/api/dynamic_documents/${document.id}`
  const handleClickPrint = () => {
    const url = `${urlPrefix}.pdf?disposition=inline`
    const w = window.open(url, '', 'toolbar=0,menubar=0,location=0')
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print()
      })
    }
  }

  return (
    <>
      <DynamicDocumentAsyncStatus id={document.id} dynamicDocument={document.dynamicDocument} />
      <DocumentContractRequestAsyncStatus documentContractRequest={document.documentContractRequest} />
      <div className="l-wrap-xxl l-contents-wrap">
        <p className={styles.previewNotice}>※従業員を複数選択している場合は５人目までプレビュー表示できます</p>
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <div className={styles.sectionTitle}>{document.title}</div>
            <div className={styles.sectionTools}>
              {!document.contractSending && !document.contractSent && (
                <Delete
                  documentId={document.id}
                  title={document.title}
                  onRequestFinish={() => dispatch(push('/documents'))}
                  disabled={isNotAllowedUpdateDynamicDocument(document.dynamicDocument)}
                  disabledReason="書類の作成・更新中は削除できません。"
                />
              )}
              {document.documentEmployeesCount < 100 && (
                <ActionButton
                  primary
                  className={styles.toolButton}
                  icon={<Print size={15} />}
                  onClick={handleClickPrint}
                  disabled={!document.dynamicDocument.downloadable}
                >
                  印刷
                </ActionButton>
              )}
              <ActionButton
                primary
                className={styles.toolButton}
                href={document.documentEmployeesCount <= 1 ? `${urlPrefix}.pdf` : `${urlPrefix}.zip`}
                download
                as={document.dynamicDocument.downloadable ? 'a' : 'span'}
                icon={<Download size={15} />}
                disabled={!document.dynamicDocument.downloadable}
              >
                ダウンロード
              </ActionButton>
            </div>
          </div>
          <div className={styles.sectionContent}>
            <PreviewContainer pdf={previewDocument} />
            <Visibility document={document} />
            {document.dynamicDocument.previewable && !document.contractSending && !document.contractSent && (
              <RelatedRecordsDirtyAlert document={document} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const PollingController = ({statuses, stopPolling, startPolling}) => {
  useEffect(() => {
    if (statuses.some((status) => status !== 'success' && status !== 'failed')) {
      startPolling(3000)
      return () => {
        stopPolling()
      }
    }
  })
  return null
}

const Show = ({
  location: {pathname},
  match: {
    params: {id},
  },
}) => {
  const client = useSelector((state) => state.client.current)
  const dispatch = useDispatch()
  const {data, error, loading, startPolling, stopPolling} = useQuery(DOCUMENT, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {id},
  })

  const handleMoveEmployees = () => {
    const path = generateDynamicPath(WITH_AUTH_ADMIN_PATHS.DOCUMENTS.DOCUMENT_ID.EMPLOYEES.INDEX, [
      {pattern: 'documentId', replacement: document.id},
    ])

    dispatch(push(path))
  }

  if (error) {
    return <Redirect to="/documents" />
  }

  const document = data?.client?.document
  if (!loading && !document) {
    dispatch(notifyError('書類がありません'))
    return <Redirect to="/documents" />
  }

  return (
    <>
      <CommonTitle pathname={pathname} id={id} />
      {loading ? (
        <div className="l-wrap-xxl l-contents-wrap">
          <LoadingPage />
        </div>
      ) : (
        <>
          <PollingController
            statuses={[document.dynamicDocument.status, document.documentContractRequest?.status].filter((v) => v)}
            {...{startPolling, stopPolling}}
          />
          <Preview document={document} />
        </>
      )}
      <FloatingButton>
        <div className={`${styles.btnContainer}`}>
          {document && (
            <>
              <Button primary onClick={handleMoveEmployees}>
                マイページ公開
              </Button>
              {client.plan_type === allowedPlanForSendContract && (
                <Button
                  primary
                  onClick={() => dispatch(push(`/documents/${document.id}/contracts/create`))}
                  disabled={
                    !document.dynamicDocument.downloadable ||
                    !document.documentEmployeesCount ||
                    !document.existContractTargets ||
                    !document.contractCreatable ||
                    document.documentContractRequest?.status === 'failed'
                  }
                  disabledReason={isNotAllowedSendContract(document)}
                >
                  電子契約送信
                </Button>
              )}
            </>
          )}
        </div>
      </FloatingButton>
    </>
  )
}

export default Show
