import {FC, ReactNode, useState} from 'react'
import Button from 'jbc-front/components/Button'
import Modal from 'jbc-front/components/CommonModal'
import {isEqual, sortBy} from 'lodash'
import classNames from 'classnames'
import {MasterFinder, MasterItem} from 'components/ui/MasterFinder'
import styles from './OfficeSelector.scss'
import {useSearchOffices} from './useSearchOffices'
import {SelectedOffices, SelectedOffice, ExtraItem} from './SelectedOffices'

const PER_PAGE = 10

export interface OfficeSelectorProps {
  selectedIds: string[]
  multiple?: boolean
  filterByCurrentOffices?: boolean
  extraItems?: ExtraItem[]
  onChange: (selectedIds: string[]) => void
  children?: (offices: SelectedOffice[]) => ReactNode
}

export const OfficeSelector: FC<OfficeSelectorProps> = ({
  selectedIds,
  multiple,
  filterByCurrentOffices,
  extraItems,
  onChange,
  children,
}) => {
  const [draftSelectedIds, setDraftSelectedIds] = useState(selectedIds)
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState(1)

  const [isOpen, setIsOpen] = useState(false)
  const showModal = () => {
    setIsOpen(true)
    setSearchKeyword(undefined)
    setCurrentPage(1)
    setDraftSelectedIds(selectedIds)
  }
  const hideModal = () => {
    setIsOpen(false)
  }

  const {offices, totalCount} = useSearchOffices(currentPage, PER_PAGE, searchKeyword, filterByCurrentOffices, !isOpen)

  const handleSearch = (keyword: string | undefined, page: number) => {
    setSearchKeyword(keyword)
    setCurrentPage(page)
  }

  const handleSelect = (item: MasterItem) => {
    if (!multiple) {
      setIsOpen(false)
      onChange([item.key])
      return
    }

    if (draftSelectedIds.includes(item.key)) {
      setDraftSelectedIds(draftSelectedIds.filter((id) => id !== item.key) || [])
    } else {
      setDraftSelectedIds([...draftSelectedIds, item.key])
    }
  }

  const handleAccept = () => {
    setIsOpen(false)
    if (!isEqual(sortBy(selectedIds), sortBy(draftSelectedIds))) {
      onChange(draftSelectedIds)
    }
  }

  const handleClear = () => {
    setIsOpen(false)
    onChange([])
  }

  return (
    <>
      <div className={styles.officeSelector} onClick={showModal}>
        <SelectedOffices
          selectedIds={selectedIds}
          extraItems={extraItems}
          render={children || (multiple ? renderSelectedOffices : renderSelectedOffice)}
        />
      </div>
      <Modal isOpen={isOpen} hideModal={hideModal}>
        <Modal.Header hideModal={hideModal}>適用事業所を選択</Modal.Header>
        <Modal.Body>
          <MasterFinder
            multiple={multiple}
            searchResults={offices?.map((office) => ({
              key: String(office.id),
              label: String(office.name),
              selected: draftSelectedIds.includes(String(office.id)),
            }))}
            totalResult={totalCount}
            currentPage={currentPage}
            perPage={PER_PAGE}
            extraItems={extraItems?.map(({key, label}) => ({
              key,
              label,
              selected: draftSelectedIds.includes(key),
            }))}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onClear={handleClear}
          />
        </Modal.Body>
        {multiple && (
          <Modal.Footer>
            <Modal.Buttons>
              <Button onClick={hideModal}>キャンセル</Button>
              <Button primary onClick={handleAccept}>
                決定
              </Button>
            </Modal.Buttons>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

const renderSelectedOffice = (offices: SelectedOffice[]) => {
  const [office] = offices
  if (office) {
    return <span>{office.name || '\u00a0'}</span>
  } else {
    return <span className={styles.placeholder}>適用事業所選択</span>
  }
}

const renderSelectedOffices = (offices: SelectedOffice[]) => {
  if (offices.length === 0) {
    return <span className={styles.placeholder}>適用事業所選択</span>
  }

  return (
    <div className={styles.selectedOffices}>
      {offices.map(({id, name, loading}) => (
        <div key={id} className={classNames(styles.selectedOffice, {[styles.selectedOfficePlaceholder]: loading})}>
          {loading ? '\u00a0' : name}
        </div>
      ))}
    </div>
  )
}
