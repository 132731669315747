import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import Notifications from 'reapop'
import theme from 'reapop-theme-wybo'
import JobcanHeader from 'jbc-front/components/JobcanHeader'
import {LoggedInHeader, NotLoggedInHeader, TmLoggedInHeader, MypageLoggedInHeader} from 'components/headers'
import {Header} from 'components/layout/Header'
import Footer from 'components/Footer'
import {asyncError} from 'store/actions/asyncError'
import {setToken} from 'store/slices/auth'
import {setCurrentClient} from 'store/slices/client'
import {setCurrentUser, setCurrentTmUser} from 'store/slices/session'
import api from 'api'
import Sidebar from 'components/Sidebar'
import TmSidebar from 'tm/components/Sidebar'
import _ from 'lodash'
import compose from 'lodash/fp/compose'
import styles from 'Layout.scss'
import {LoadingProgress} from 'components/LoadingProgress'
import AsyncTask from 'AsyncTask'
import CustomNotifications from 'components/CustomNotifications'
import {ZopimLoader} from 'components/ZopimLoader'
import {setTelemetryUser} from 'libs/telemetry'

const isVisibleJobcanHeader = (currentUser) => currentUser?.employee && currentUser?.email

class NotLoggedIn extends Component {
  render() {
    return (
      <div>
        <LoadingProgress />
        <Notifications theme={theme} />
        <Header>
          <NotLoggedInHeader />
        </Header>
        <div className={styles.main}>
          <div className="l-main-contents">{this.props.children}</div>
          <Footer />
        </div>
      </div>
    )
  }
}

class LoggedIn extends Component {
  componentDidMount() {
    this.props.fetchCurrentUser()
    this.props.showCurrentClient()
  }

  render() {
    const {history, currentUser, client, selectedOffices, children} = this.props
    const offset = isVisibleJobcanHeader(currentUser) ? 80 : 56
    return (
      <div>
        <ZopimLoader client={client} currentUser={currentUser} />
        <LoadingProgress />
        <CustomNotifications />
        <Header>
          {isVisibleJobcanHeader(currentUser) && (
            <JobcanHeader
              label="労務"
              user={currentUser.email}
              logout={() => history.push('/logout')}
              jobcanLogoUrl={process.env['JBC_ID_SERVER']}
            />
          )}
          <LoggedInHeader
            currentUser={currentUser}
            client={
              ['full_admin', 'office_admin'].includes(_.get(currentUser, 'client_role.client_role_type')) && client
            }
          />
        </Header>
        <div className={styles.main} key={selectedOffices}>
          <Sidebar offset={offset} />
          <div className={styles.marginSidebar}>
            <div className="l-main-contents">
              <AsyncTask>{children}</AsyncTask>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

class MypageLoggedIn extends Component {
  componentDidMount() {
    this.props.fetchCurrentUser()
    this.props.showCurrentClient()
  }

  render() {
    const {history, currentUser, client, children} = this.props
    const offset = isVisibleJobcanHeader(currentUser) ? 80 : 56
    return (
      <div>
        <ZopimLoader client={client} currentUser={currentUser} />
        <LoadingProgress />
        <CustomNotifications />
        <Header>
          {isVisibleJobcanHeader(currentUser) && (
            <JobcanHeader
              label="労務"
              user={currentUser.email}
              logout={() => history.push('/logout')}
              jobcanLogoUrl={process.env['JBC_ID_SERVER']}
            />
          )}
          <MypageLoggedInHeader currentUser={currentUser} />
        </Header>
        <div className={styles.main}>
          <Sidebar offset={offset} />
          <div className={styles.marginSidebar}>
            <div className="l-main-contents">
              <AsyncTask>{children}</AsyncTask>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

class TmLoggedIn extends Component {
  componentDidMount() {
    this.props.fetchCurrentTmUser()
    this.props.showTmCurrentClient()
  }

  render() {
    const {history, currentTmUser, client, children} = this.props
    const offset = isVisibleJobcanHeader(currentTmUser) ? 80 : 56
    return (
      <div>
        <LoadingProgress />
        <CustomNotifications />
        <Header>
          {isVisibleJobcanHeader(currentTmUser) && (
            <JobcanHeader
              label="人材"
              user={currentTmUser.email}
              logout={() => history.push('/tm/logout')}
              jobcanLogoUrl={process.env['JBC_ID_SERVER']}
            />
          )}
          <TmLoggedInHeader currentTmUser={currentTmUser} client={client} />
        </Header>
        <div className={styles.main}>
          <TmSidebar offset={offset} />
          <div className={styles.marginSidebar}>
            <div className={'l-main-contents ' + styles.tmMainContentsSp}>
              <AsyncTask>{children}</AsyncTask>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.session.currentUser,
  client: state.client.current,
  selectedOffices: state.session.selectedOffices,
})

export const mapDispatchToProps = (dispatch) => ({
  async fetchCurrentUser(token) {
    try {
      const data = await api.createWithAuth(token).users.getCurrent(['employee'])
      dispatch(setToken(data.data.access_token))
      dispatch(setCurrentUser(data.data))
      setTelemetryUser({id: `lms${data.data.id}`, customer_code: data.data.customer_code})
      return data.data
    } catch (err) {
      if (_.get(err, 'response.status') === 401) {
        err.redirectToLogin = true
      }
      dispatch(asyncError(err))
    }
  },
  async fetchCurrentTmUser(token) {
    try {
      const data = await api.createWithAuth(token).tm.tmUsers.getCurrent(['employee'])
      dispatch(setToken(data.data.access_token))
      dispatch(setCurrentTmUser(data.data))
      setTelemetryUser({id: `tm${data.data.id}`})
      return data.data
    } catch (err) {
      if (_.get(err, 'response.status') === 401) {
        err.redirectToLogin = true
      }
      dispatch(asyncError(err))
    }
  },
  async showCurrentClient(token) {
    try {
      const data = await api.createWithAuth(token).clients.getCurrent(['office'])
      dispatch(setCurrentClient(data))
    } catch (err) {
      if (_.get(err, 'response.status') === 401) {
        err.redirectToLogin = true
      }
      dispatch(asyncError(err))
    }
  },
  async showTmCurrentClient(token) {
    try {
      const data = await api.createWithAuth(token).tm.clients.getCurrent(['office'])
      dispatch(setCurrentClient(data))
    } catch (err) {
      if (_.get(err, 'response.status') === 401) {
        err.redirectToLogin = true
      }
      dispatch(asyncError(err))
    }
  },
})

export default {
  NotLoggedIn: NotLoggedIn,

  LoggedIn: compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoggedIn),

  MypageLoggedIn: compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MypageLoggedIn),

  TmLoggedIn: compose(
    withRouter,
    connect(
      (state) => ({
        token: state.auth.token,
        currentTmUser: state.session.currentTmUser,
        client: state.client.current,
      }),
      mapDispatchToProps
    )
  )(TmLoggedIn),
}
