import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import styles from 'employees/Invite.scss'
import {actionCreators} from 'actions'
import api from 'api'
import {getGroups} from 'employees/form/EmploymentInfomation'
import {bindActionCreators} from 'redux'
import Button from 'jbc-front/components/Button'
import {
  getCurrentQueryFromLocation,
  fetchEmployees as yeaFetchEmployees,
  getSavedDisplayEmployeeLimit,
} from 'employees/list/utils'
import PaginatorWithResult from 'employees/list/PaginatorWithResult'
import SearchForm from 'employees/list/SearchForm'
import SortableTh from 'employees/list/SortableTh'
import {StaffModal} from 'employees/CoordinationTargets'
import {Hint} from 'employees/Invite'
import {Redirect} from 'react-router-dom'
import moment from 'moment'
import ActionButton from 'jbc-front/components/ActionButton'
import {CheckboxFilter} from 'jbc-front/components/icons'
import {HoverNote} from 'components/ui/HoverNote'
import classnames from 'classnames'
import {useNotify} from 'hooks/useNotify'
import {asyncError} from 'store/actions/asyncError'

export const Row = (props) => {
  const [showHint, setShowHint] = useState(false)
  const [hint, setHint] = useState(null)

  const handleClick = () => {
    const {disabledMessage} = props
    if (disabledMessage) {
      setShowHint(true)
      setHint(disabledMessage)
    } else {
      props.onClick(props.employee)
    }
  }
  const handleInputClick = (e) => {
    e.stopPropagation()
    const {employee, onInputClick} = props
    onInputClick(employee.id, employee.display_last_name, {staff_code: employee.staff_code})
  }

  const hideHint = () => {
    setShowHint(false)
  }

  const {employee, selected, disabledMessage, yearEndAdjCoordinationTarget} = props
  const isNewSelected = !yearEndAdjCoordinationTarget && selected
  const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
  const disabledClass = disabledMessage ? ' u-cur-notallowed' : ' u-cur-pointer'
  const cursorStyleClass = classnames({
    'u-cur-notallowed': disabledClass,
    'u-cur-pointer': !disabledClass,
  })
  return (
    <tr className={classnames({[styles.backgroundColorBlue]: isNewSelected})}>
      <td onClickCapture={handleClick} className={classnames(selectedClass, disabledClass)}>
        {showHint && <Hint onClick={hideHint}>{hint}</Hint>}
        <input type="checkbox" checked={selected} className={cursorStyleClass} readOnly />
      </td>
      <td className={classnames(styles.staffcodeColumn)} onClick={handleInputClick}>
        <a className="u-txt-link">{employee.staff_code || '未入力'}</a>
      </td>
      <td className={classnames(styles.nameColumn)}>{recordDisplay.fullName(employee)}</td>
      <td className={classnames(styles.employmentStatusColumn)}>
        {recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}
      </td>
      <td className={classnames(styles.groupColumn)}>
        {recordDisplay(
          !_.isEmpty(getGroups(employee)) &&
            getGroups(employee).map((group, index) => (
              <span key={index}>
                {group}
                <br />
              </span>
            ))
        )}
      </td>
      <td className={classnames(styles.positionColumn)}>
        {recordDisplay(_.get(employee, 'personnel_history.position_name'))}
      </td>
      <td className={classnames(styles.dateColumn)}>
        {recordDisplay(employee.joined_at && employee.joined_at.replace(/-/g, '/'))}
      </td>
    </tr>
  )
}

// NOTE: 年調の年度に合わせて変更してください
const JOINED_THRESHOLD = moment('2024-01-01')
const isJoinedNextYear = (employee) => employee.joined_at && moment(employee.joined_at).isSameOrAfter(JOINED_THRESHOLD)

const selectByAll = (employee) => !!employee.staff_code && !isJoinedNextYear(employee)

const isSelected = (employee, selected) =>
  selected[employee.id] === undefined
    ? employee.staff_code && !isJoinedNextYear(employee) && employee.year_end_adj_coordination_target
    : employee.staff_code && !isJoinedNextYear(employee) && selected[employee.id]

const isAllSelected = (employees, selected) =>
  employees.some((employee) => isSelected(employee, selected)) &&
  employees.every((employee) => isSelected(employee, selected) || !selectByAll(employee))

const defaultAdditionalParams = Object.freeze({embed: ['personnel_history', 'year_end_adj_coordination_target']})

export const List = (props) => {
  const dispatch = useDispatch()
  const employees = useSelector((state) => state.employees.list.data)
  const targetTotalCount = useSelector((state) => state.employees.list.yeaTargetTotalCount)
  const token = useSelector((state) => state.auth.token)
  const query = useSelector((state) => state.employees.query)
  const selected = useSelector((state) => state.employees.selected)
  const location = useSelector((state) => state.router.location)
  const user = useSelector((state) => state.session.currentUser)
  const client = useSelector((state) => state.client.current)
  const additionalParams = {
    ...defaultAdditionalParams,
  }
  const fetchEmployees = () => dispatch(yeaFetchEmployees(query, additionalParams))

  const changeMulti = bindActionCreators(actionCreators.employees.changeMulti, dispatch)
  const clearSelected = bindActionCreators(actionCreators.employees.clearSelected, dispatch)
  const notify = useNotify()

  const [type] = useState(null)
  const [employeeId, setEmployeeId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState('')
  const [showConfirmModal] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [searchYeaCoordinationTargetEmployees, setSearchYeaCoordinationTargetEmployees] = useState(false)
  const [checkedEmployeeCount, setCheckedEmployeeCount] = useState(0)
  const [checkboxFilterColor, setCheckboxFilterColor] = useState('#999999')

  const state = {
    type: type,
    employeeId: employeeId,
    isOpen: isOpen,
    name: name,
    showConfirmModal: showConfirmModal,
    initialValues: initialValues,
    submitting: submitting,
  }

  const handleRowClick = (employee) => {
    if (!isSelected(employee, selected)) {
      setCheckedEmployeeCount(checkedEmployeeCount + 1)
    } else {
      setCheckedEmployeeCount(checkedEmployeeCount - 1)
    }
    changeMulti([
      {
        [employee.id]:
          selected[employee.id] === undefined ? !employee.year_end_adj_coordination_target : !selected[employee.id],
      },
    ])
  }

  const saveTargets = async () => {
    try {
      setSubmitting(true)
      await api.createWithAuth(token).employees.yearEndAdj.updateTargets({selected})
      setSubmitting(false)
      dispatch(push('/employees/year_end_adj_coordination_targets/?employment_status=all'))
      notify('保存しました', 'success')
      clearSelected()
    } catch (err) {
      dispatch(asyncError(err))
      setSubmitting(false)
    }
  }

  const selectedEmployees = () => {
    if (searchYeaCoordinationTargetEmployees) {
      setCheckboxFilterColor('#999999')
    } else {
      setCheckboxFilterColor('#3498DB')
    }
    setSearchYeaCoordinationTargetEmployees(!searchYeaCoordinationTargetEmployees)
    dispatch(push('/employees/year_end_adj_coordination_targets/?employment_status=all'))
  }

  const disabledMessage = (employee) => {
    if (!employee.staff_code) {
      return 'スタッフコードを入力してください'
    }
    if (isJoinedNextYear(employee)) {
      return `${JOINED_THRESHOLD.format('YYYY年M月')}以降の入社です`
    }
    return null
  }

  const handleSelectAllClick = () => {
    const allSelected = isAllSelected(employees, selected)
    if (allSelected) {
      changeMulti(
        employees.filter((employee) => isSelected(employee, selected)).map((employee) => ({[employee.id]: false}))
      )
      setCheckedEmployeeCount(0)
    } else {
      changeMulti(
        employees
          .filter(selectByAll)
          .filter((employee) => !isSelected(employee, selected))
          .map((employee) => ({[employee.id]: true}))
      )
      const allSelectedEmployees = employees.filter(selectByAll)
      setCheckedEmployeeCount(allSelectedEmployees.length)
    }
  }

  useEffect(() => {
    if (targetTotalCount) {
      setCheckedEmployeeCount(Number(targetTotalCount))
    }
  }, [employees])

  const hideModal = (loadData = false) => {
    setIsOpen(false)
    if (loadData) {
      fetchEmployees(getCurrentQueryFromLocation(location, user))
    }
  }

  const handleInputClick = (employeeId, name, initialValues = {}) => {
    setEmployeeId(employeeId)
    setIsOpen(true)
    setName(name)
    setInitialValues(initialValues)
  }

  const {
    location: {pathname},
  } = props
  const allSelected = isAllSelected(employees, selected)
  const allClickClass = allSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
  additionalParams['search_yea_coordination_target_employees'] = searchYeaCoordinationTargetEmployees

  const isSelectedEmployee = (selected, employee, isJoinedNextYear) => {
    if (selected[employee.id] === undefined) {
      return employee.staff_code && !isJoinedNextYear(employee) && employee.year_end_adj_coordination_target
    } else {
      return employee.staff_code && !isJoinedNextYear(employee) && selected[employee.id]
    }
  }

  return (
    <div>
      {client.plan_type === 'free_plan' && <Redirect to="/employees" />}
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">対象従業員選択（年末調整）</h1>
        <p className="m-title-main-note">
          年末調整にインポートする従業員を指定します。
          <br />
          ここで指定した従業員はジョブカン年末調整で「インポート設定されている従業員」を選択した場合に連携の対象となります。
        </p>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <SearchForm additionalParams={additionalParams} />
          <div className="u-mb10">
            <p className="m-title-main-note">選択した従業員: {checkedEmployeeCount}名</p>
            <HoverNote
              message={
                !_.isEmpty(selected) ? '対象者を保存してから絞り込みを行ってください' : '対象従業員の絞り込みを行えます'
              }
            >
              <ActionButton
                className={styles.buttonCoordinations}
                onClick={selectedEmployees}
                disabled={submitting || !_.isEmpty(selected)}
                icon={<CheckboxFilter size={16} color={checkboxFilterColor} />}
              />
            </HoverNote>
          </div>
          <div className="l-overflow-scroll">
            <table className="m-table-list m-table-fixed">
              <thead>
                <tr>
                  <th onClick={handleSelectAllClick} className={allClickClass + ' m-table-list-check u-cur-pointer'}>
                    <input type="checkbox" readOnly checked={allSelected} />
                  </th>
                  <SortableTh field="staff_code" globalClassName={styles.staffcodeColumn}>
                    スタッフコード
                  </SortableTh>
                  <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
                    氏名
                  </SortableTh>
                  <SortableTh field="employment_type" globalClassName={styles.employmentStatusColumn}>
                    雇用形態
                  </SortableTh>
                  <SortableTh field="group" globalClassName={styles.groupColumn}>
                    グループ
                  </SortableTh>
                  <SortableTh field="position" globalClassName={styles.positionColumn}>
                    役職
                  </SortableTh>
                  <SortableTh field="joined_at" globalClassName={styles.dateColumn}>
                    入社日
                  </SortableTh>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <Row
                    key={employee.id}
                    onClick={handleRowClick}
                    onInputClick={handleInputClick}
                    selected={isSelectedEmployee(selected, employee, isJoinedNextYear)}
                    {...{employee}}
                    disabledMessage={disabledMessage(employee)}
                    yearEndAdjCoordinationTarget={employee.year_end_adj_coordination_target}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(pathname, user)} />
        </div>
        <div className={classnames(styles.float, 'u-mt20 u-ta-c')}>
          <Button
            primary
            onClick={saveTargets}
            disabled={submitting || _.isEmpty(selected)}
            className={classnames(styles.floatButton)}
          >
            対象者を保存する
          </Button>
        </div>
        <StaffModal hideModal={hideModal} {...state} />
      </div>
    </div>
  )
}

export default List
