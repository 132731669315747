import {createAction} from '@reduxjs/toolkit'

// @ts-ignore
import {addNotification} from 'reapop'

export const notifySuccess = (message: string) =>
  addNotification({
    message,
    position: 'tc',
    status: 'success',
    dismissAfter: 3000,
  })

export const notifyError = createAction<string>('NOTIFY_ERROR')
