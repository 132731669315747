import {useFetchData} from 'hooks/api/useFetchData'
import {CountryCode, DateString, PrefectureCode} from 'types/api'
import {
  Employee,
  EmploymentStatus,
  Gender,
  HandicapType,
  MaritalStatus,
  NationalType,
  RemunerationType,
  RetireType,
  WidowType,
  WorkingStudentType,
} from 'types/api/employees/employee'
import {ProcedureType} from 'types/api/procedureStatuses/procedureType'

type Query = Partial<{
  _page: number
  limit: number
  offset: number
  q: string
  employment_status: ['all' | EmploymentStatus]
  sort:
    | 'joined_at'
    | 'retired_at'
    | 'staff_code'
    | 'full_name_kana'
    | 'group'
    | 'email'
    | 'office'
    | 'employment_type'
    | 'position'
    | 'occupation_ids'
    | null
  order: 'asc' | 'desc' | null
  exist_my_number: boolean
  embed: [
    | 'user'
    | 'employee_dependents'
    | 'contract_periods'
    | 'welfare_pension_insurance'
    | 'employment_insurance'
    | 'health_insurance'
    | 'emergency_contact'
    | 'resident_card'
    | 'commuting_expenses'
    | 'employee_resumes'
    | 'received_request_statuses'
    | 'my_number_input_request'
    | 'personnel_history'
    | 'detail_input_request'
    | 'could_send_input_request'
    | 'role'
    | 'year_end_adj_coordination_target'
  ]
  only_tm_user: boolean
  role_ids: [number]
  client_role_ids: [number]
  name: string
  email: string
  staff_code: string
  group_ids: [number]
  position_ids: [number]
  employment_type_ids: [number]
  occupation_ids: [number]
  office_ids: [number]
  joined_health_insurance: boolean | null
  joined_welfare_pension_insurance: boolean | null
  joined_employment_insurance: boolean | null
  age_from: number
  age_to: number
  age_base_date: DateString
  joined_at_start: DateString
  joined_at_end: DateString
  former_name: string
  birthday_start: DateString
  birthday_end: DateString
  genders: [Gender]
  countries: [CountryCode]
  prefecture_ids: [PrefectureCode]
  nation_ids: [CountryCode]
  national_types: [NationalType]
  visa_type_ids: [number]
  retired_at_start: DateString
  retired_at_end: DateString
  retire_types: [RetireType]
  has_dependents: boolean
  marital_statuses: [MaritalStatus]
  remuneration_types: [RemunerationType]
  handicap_types: [HandicapType]
  widow_types: [WidowType]
  working_student_types: [WorkingStudentType]
  tag_ids: [number]
  skills: [string]
  qualifications: [string]
  language_ids: [number]
  language_level_ids: [number]
  languages_qualification_name: string
  languages_score_from: number
  languages_score_to: number
  projects: [string]
  received_request_status_procedure_type: ProcedureType
  received_request_status_status: 'doing' | 'done'
}>

export const useFetchEmployees = (query?: Query) => {
  const path = '/api/employees'
  return useFetchData<Employee[]>(path, [], {params: query})
}
