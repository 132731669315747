import React from 'react'
import {
  reduxForm,
  Field,
  Fields,
  FieldArray,
  formValueSelector,
  FormSection,
  autofill as autofillForm,
} from 'redux-form'
import {
  CustomText,
  CustomNumber,
  CustomRadio,
  CustomCheckbox,
  CustomSelect,
  CustomCalender,
  CustomFileupload,
  Any,
  Required as RequiredIcon,
  PlusSquare,
  Plus,
  Delete,
} from 'jbc-front/components/icons'
import Button from 'jbc-front/components/Button'
import Modal from 'jbc-front/components/Modal'
import {connect} from 'react-redux'
import {TextField, TextAreaField, RadioField, Error} from 'jbc-front/components/Form'
import styles from 'settings/employeeCustom/EmployeeFieldGroup/EmployeeFieldGroupForm.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import ColorSelect from 'settings/employeeCustom/ColorSelect'
import _ from 'lodash'
import {toFormValues, toBooleanProps} from 'utils'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import PermissionField from 'settings/employeeCustom/CustomEmployeeFieldGroup/PermissionField'
import {minNumber, maxNumber, number, floatValue} from 'validators'

const formName = 'fieldGroupForm'
const selector = formValueSelector(formName)
const autofill = autofillForm.bind(null, formName)

const typeText = (type) => {
  switch (type) {
    case 'single_line_text':
    case 'multi_line_text':
      return '文字列'
    case 'number':
      return '数字'
    case 'radio_button':
      return 'ラジオボタン'
    case 'checkbox':
      return 'チェックボックス'
    case 'pulldown':
      return 'プルダウン'
    case 'date':
      return '日付'
    case 'file':
      return 'ファイル'
  }
}

const defaultPermissions = [
  {
    client_role_id: 2,
    permission: 'not_display',
  },
]

const addButtons = [
  {
    icon: CustomText,
    initialValue: {
      custom_employee_field: {
        field_type: 'single_line_text',
        indispensability_type: 'optional',
        text_limit_type: 'none',
      },
      custom_employee_field_permissions: defaultPermissions,
    },
  },
  {
    icon: CustomNumber,
    initialValue: {
      custom_employee_field: {
        field_type: 'number',
        indispensability_type: 'optional',
        number_limit_type: 'none',
        allow_decimal_point: false,
        allow_comma: false,
      },
      custom_employee_field_permissions: defaultPermissions,
    },
  },
  {
    icon: CustomRadio,
    initialValue: {
      custom_employee_field: {field_type: 'radio_button', indispensability_type: 'optional'},
      custom_employee_field_permissions: defaultPermissions,
    },
  },
  {
    icon: CustomCheckbox,
    initialValue: {
      custom_employee_field: {field_type: 'checkbox', indispensability_type: 'optional'},
      custom_employee_field_permissions: defaultPermissions,
    },
  },
  {
    icon: CustomSelect,
    initialValue: {
      custom_employee_field: {field_type: 'pulldown', indispensability_type: 'optional'},
      custom_employee_field_permissions: defaultPermissions,
    },
  },
  {
    icon: CustomCalender,
    initialValue: {
      custom_employee_field: {field_type: 'date', indispensability_type: 'optional'},
      custom_employee_field_permissions: defaultPermissions,
    },
  },
  {
    icon: CustomFileupload,
    initialValue: {
      custom_employee_field: {field_type: 'file', indispensability_type: 'optional'},
      custom_employee_field_permissions: defaultPermissions,
    },
  },
]

/* eslint-disable no-unused-vars */
const renderError = ({names, label, ...fields}) => {
  /* eslint-enable no-unused-vars */
  const field = _.find(fields, (field) => field.meta && field.meta.touched && field.meta.error)
  if (field) {
    return <Error {...field} label={label} />
  }
  return null
}

const renderInput = ({input, disabled, meta: {touched, error}, ...rest}) => (
  <input
    {...input}
    disabled={disabled}
    {...(touched && error ? {className: styles.invalid} : {})}
    type="text"
    {...rest}
  />
)

const Required = ({input: {onBlur, value}}) => {
  const Icon = value == 'indispensable' ? RequiredIcon : Any
  return (
    <div className={styles.noteContainer}>
      <Icon
        onClick={() => onBlur(value === 'indispensable' ? 'optional' : 'indispensable')}
        className="u-cur-pointer"
      />
      <div className={styles.note}>クリックで必須・任意の変更ができます</div>
    </div>
  )
}

const Description = ({hasDescription, addDescription}) =>
  hasDescription ? (
    <div>
      <TextAreaField
        name="description"
        label="説明文"
        rows="1"
        icons={<Field component={ColorSelect} name="description_color_by_rgb" />}
      />
    </div>
  ) : (
    <div className="u-ta-c">
      <ActionButton icon={<PlusSquare />} onClick={addDescription}>
        説明を追加
      </ActionButton>
    </div>
  )

const textFormats = [
  {label: '改行無し', value: 'single_line_text'},
  {label: '改行有り', value: 'multi_line_text'},
]

const textLimits = [
  {label: 'なし', value: 'none'},
  {label: '固定', value: 'fixed'},
  {label: '範囲指定', value: 'range'},
]

const numberLimits = [
  {label: 'なし', value: 'none'},
  {
    label: '範囲指定',
    value: 'range',
    note: '【数字】の最大値は1億です。長い番号を入力する場合は【文字列】をご利用ください。',
  },
]

const labelValidate = (value) => (value && value.match(/^\d+$/) ? 'は文字列を含むよう指定してください' : undefined)

const renderTextField = ({limitType, fieldType}) => (
  <div>
    <div className={styles.elementWrap}>
      <div className={styles.elementHeader}>形式</div>
      <div className={styles.elementContent}>
        <RadioField options={textFormats} name="field_type" />
      </div>
    </div>
    {fieldType === 'single_line_text' && (
      <div className={styles.elementWrap}>
        <div className={styles.elementHeader}>文字数制限</div>
        <div className={styles.elementContent}>
          <RadioField options={textLimits} name="text_limit_type" />
          {limitType === 'fixed' && (
            <div className={styles.formWrap}>
              <Field component={renderInput} type="text" name="text_length" validate={[maxNumber(100), number]} />
              文字
              <Field component={Error} name="text_length" validate={[maxNumber(100), number]} label="文字数制限" />
            </div>
          )}
          {limitType === 'range' && (
            <div className={styles.formWrap}>
              <Field
                component={renderInput}
                type="text"
                name="minimum_text_length"
                validate={[maxNumber(100), number]}
              />
              以上
              <Field
                component={renderInput}
                type="text"
                name="maximum_text_length"
                validate={[maxNumber(100), number]}
              />
              以下
              <Fields
                component={renderError}
                names={['maximum_text_length', 'minimum_text_length']}
                label="文字数制限"
              />
            </div>
          )}
        </div>
      </div>
    )}
  </div>
)

const CustomTextField = connect((state, {index}) => ({
  limitType: selector(state, `custom_employee_fields[${index}].custom_employee_field.text_limit_type`),
  fieldType: selector(state, `custom_employee_fields[${index}].custom_employee_field.field_type`),
}))(renderTextField)

const allowDecimalOptions = [
  {value: 'true', label: '可能'},
  {value: 'false', label: '不可'},
]
const allowCommaOptions = [
  {value: 'true', label: '使用する'},
  {value: 'false', label: '使用しない'},
]

const renderNumberField = ({limitType}) => (
  <div>
    <div className={styles.elementWrap}>
      <div className={styles.elementHeader}>範囲指定</div>
      <div className={styles.elementContent}>
        <RadioField options={numberLimits} name="number_limit_type" />
        {limitType === 'range' && (
          <div className={styles.formWrap}>
            <Field
              component={renderInput}
              type="text"
              name="minimum_number"
              style={{width: '120px'}}
              validate={[floatValue, maxNumber(100000000), minNumber(-100000000)]}
            />
            以上
            <Field
              component={renderInput}
              type="text"
              name="maximum_number"
              style={{width: '120px'}}
              validate={[floatValue, maxNumber(100000000), minNumber(-100000000)]}
            />
            以下
            <Fields component={renderError} names={['minimum_number', 'maximum_number']} label="範囲指定" />
          </div>
        )}
      </div>
    </div>
    <div className={styles.elementWrap}>
      <div className={styles.elementHeader}>小数点入力</div>
      <div className={styles.elementContent}>
        <RadioField name="allow_decimal_point" {...toBooleanProps} options={allowDecimalOptions} />
      </div>
    </div>
    <div className={styles.elementWrap}>
      <div className={styles.elementHeader}>桁区切り（,）</div>
      <div className={styles.elementContent}>
        <RadioField name="allow_comma" {...toBooleanProps} options={allowCommaOptions} />
      </div>
    </div>
  </div>
)

const CustomNumberField = connect((state, {index}) => ({
  limitType: selector(state, `custom_employee_fields[${index}].custom_employee_field.number_limit_type`),
}))(renderNumberField)

const FieldDescription = connect(
  (state, {index}) => ({
    hasDescription: selector(state, `custom_employee_fields[${index}].custom_employee_field._has_description`),
  }),
  (dispatch, {index}) => ({
    addDescription() {
      dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._has_description`, true))
    },
  })
)(Description)

const renderOptions = ({fields}) => (
  <div>
    {fields.map((field, index) => (
      <FormSection name={field} key={index}>
        <TextField
          name="label"
          label={`選択肢${index + 1}`}
          required
          icons={
            <Delete
              onClick={() => {
                fields.remove(index)
              }}
              size={20}
              className="u-cur-pointer"
            />
          }
        />
      </FormSection>
    ))}
    <div className="u-mb20 u-ta-c">
      <ActionButton
        icon={<Plus />}
        onClick={() => {
          fields.push({})
        }}
      >
        選択肢追加
      </ActionButton>
    </div>
  </div>
)

const Drag = SortableHandle(({children, ...props}) => <div {...props}>{children}</div>)

const Label = ({fieldType, label, fieldId, isOpen, isSubmitted, isCanceled, onFocus, onBlur, onSubmit, onCancel}) => (
  <React.Fragment>
    <TextField
      name="custom_employee_field.label"
      label="ラベル"
      required
      readOnly={fieldId && !isSubmitted}
      validate={labelValidate}
      onFocus={fieldId && !isSubmitted && !isCanceled ? onFocus : null}
      onBlur={onBlur}
      icons={[
        fieldType !== 'checkbox' && (
          <Field key="required" component={Required} name="custom_employee_field.indispensability_type" />
        ),
        <Field key="setting" component={PermissionField} name="custom_employee_field_permissions" label={label} />,
      ]}
    />
    <Modal
      isOpen={isOpen}
      onSubmit={onSubmit}
      hideModal={onCancel}
      body={
        <div className={styles.warning}>
          ラベルを変更するとデータ連動に不具合が出る恐れがあります。
          <br />
          ラベルを変更しますか？
        </div>
      }
      header="ラベルの変更"
      cancel="キャンセル"
      submit="変更"
    />
  </React.Fragment>
)

const FieldLabel = connect(
  (state, {_index: index}) => ({
    fieldType: selector(state, `custom_employee_fields[${index}].custom_employee_field.field_type`),
    label: selector(state, `custom_employee_fields[${index}].custom_employee_field.label`),
    fieldId: selector(state, `custom_employee_fields[${index}].custom_employee_field.id`),
    isOpen: selector(state, `custom_employee_fields[${index}].custom_employee_field._show_label_modal`),
    isSubmitted: selector(state, `custom_employee_fields[${index}].custom_employee_field._submit_label_modal`),
    isCanceled: selector(state, `custom_employee_fields[${index}].custom_employee_field._cancel_label_modal`),
    index,
  }),
  (dispatch) =>
    (state, {_index: index}) => ({
      onFocus() {
        dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._show_label_modal`, true))
      },
      onBlur() {
        dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._cancel_label_modal`, false))
      },
      onSubmit() {
        dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._show_label_modal`, false))
        dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._submit_label_modal`, true))
      },
      onCancel() {
        dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._show_label_modal`, false))
        dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._cancel_label_modal`, true))
      },
    })
)(Label)

const renderField = ({fieldType, fieldId, name, index, isOpen, showModal, hideModal, onDeleteClick}) => (
  <FormSection name={name}>
    <div className={styles.fieldDrag}>
      <div className={styles.fieldWrap}>
        <div className={styles.fieldHeader}>
          <Drag className={styles.dragHandle}>{typeText(fieldType)}</Drag>
          <Delete onClick={fieldId ? showModal : onDeleteClick} size={20} className={styles.deleteIcon} />
        </div>
        <div className={styles.fieldContent}>
          <FieldLabel index={index} _index={index} />
          <FormSection name="custom_employee_field">
            {['single_line_text', 'multi_line_text'].includes(fieldType) && <CustomTextField index={index} />}
            {fieldType === 'number' && <CustomNumberField index={index} />}
          </FormSection>
          {['radio_button', 'pulldown'].includes(fieldType) && (
            <FieldArray name="custom_employee_field_options" component={renderOptions} />
          )}
          <FormSection name="custom_employee_field">
            <FieldDescription index={index} />
          </FormSection>
        </div>
        <Modal
          isOpen={isOpen}
          onSubmit={onDeleteClick}
          hideModal={hideModal}
          body={
            <div className={styles.warning}>
              入力されている従業員の情報も削除されます。
              <br />
              本当に項目を削除しますか？
            </div>
          }
          header="項目の削除"
          cancel="キャンセル"
          submit="削除"
        />
      </div>
    </div>
  </FormSection>
)

const CustomField = SortableElement(
  connect(
    (state, {_index: index}) => ({
      fieldType: selector(state, `custom_employee_fields[${index}].custom_employee_field.field_type`),
      fieldId: selector(state, `custom_employee_fields[${index}].custom_employee_field.id`),
      isOpen: selector(state, `custom_employee_fields[${index}].custom_employee_field._show_delete_modal`),
      index,
    }),
    (dispatch) =>
      (state, {_index: index}) => ({
        showModal() {
          dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._show_delete_modal`, true))
        },
        hideModal() {
          dispatch(autofill(`custom_employee_fields[${index}].custom_employee_field._show_delete_modal`, false))
        },
      })
  )(renderField)
)

const FieldsContainer = SortableContainer(({fields}) => (
  <div>
    {fields.map((field, index) => (
      <CustomField
        key={index}
        index={index}
        _index={index}
        name={field}
        onDeleteClick={() => {
          fields.remove(index)
        }}
      />
    ))}
  </div>
))

const renderFields = ({fields, draging, setDraging}) => (
  <div {...(draging ? {className: styles.draging} : {})}>
    <FieldsContainer
      lockAxis="y"
      transitionDuration={0}
      lockToContainerEdges
      useDragHandle
      helperClass={styles.itemDraged}
      fields={fields}
      onSortEnd={({oldIndex, newIndex}) => {
        fields.move(oldIndex, newIndex)
        setDraging(false)
      }}
      onSortStart={() => {
        setDraging(true)
      }}
    />
    <div className={styles.addButtonWrap}>
      {addButtons.map((button) => {
        const Icon = button.icon
        return (
          <div
            key={button.initialValue.custom_employee_field.field_type}
            className={styles.addButton}
            onClick={() => {
              fields.push(button.initialValue)
            }}
          >
            <Icon size={16} />
            <div className={styles.note}>{typeText(button.initialValue.custom_employee_field.field_type)}を追加</div>
          </div>
        )
      })}
    </div>
  </div>
)

const CustomFields = connect(
  (state) => ({
    draging: selector(state, '_draging'),
  }),
  (dispatch) => ({
    setDraging(draging) {
      dispatch(autofill('_draging', draging))
    },
  })
)(renderFields)

const FieldGroupDescription = connect(
  (state) => ({
    hasDescription: selector(state, 'custom_employee_field_group._has_description'),
  }),
  (dispatch) => ({
    addDescription() {
      dispatch(autofill('custom_employee_field_group._has_description', true))
    },
  })
)(Description)

export const CustomEmployeeFieldGroupForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit}>
    <div className={styles.panel}>
      <div className={styles.panelHead}>
        <h2>項目グループ名</h2>
        <FormSection name="custom_employee_field_group">
          <TextField name="label" />
          <div className="u-pr50 u-pl50">
            <FieldGroupDescription />
          </div>
        </FormSection>
      </div>
      <div className={styles.panelBody}>
        <FieldArray name="custom_employee_fields" component={CustomFields} />
      </div>
    </div>
    <div className="u-ta-c">
      <Button primary onClick={handleSubmit} disabled={submitting}>
        保存
      </Button>
    </div>
  </form>
))

export const makeInitialValues = (fieldGroup, fields = []) => {
  if (_.isEmpty(fieldGroup)) {
    return {custom_employee_field_group: {icon_name: 'Custom2', icon_color: 0x3498db}}
  }
  return {
    custom_employee_field_group: {
      ...toFormValues(fieldGroup),
      _has_description: !!fieldGroup.description,
    },
    custom_employee_fields: fields.map((field) => ({
      custom_employee_field: {...toFormValues(field), _has_description: !!field.description},
      custom_employee_field_options: field.custom_employee_field_options
        ? field.custom_employee_field_options.map(toFormValues)
        : [],
      custom_employee_field_permissions: field.custom_employee_field_permissions
        ? field.custom_employee_field_permissions.map(toFormValues)
        : [],
    })),
  }
}

export const formatValues = ({custom_employee_fields = [], ...values}) => ({
  ...values,
  custom_employee_fields: custom_employee_fields.map((field, index) => ({
    ...field,
    ...(field.custom_employee_field
      ? {
          custom_employee_field: {...field.custom_employee_field, position: index},
        }
      : {}),
  })),
})
