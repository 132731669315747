import _ from 'lodash'
import postWithFile, {bulkedToFormDataPaires, toFormData} from 'api/postWithFile'
import {formatValues} from 'employees/utils'

const employees = (roumuAxios) => ({
  list(query, embed = [], params = {}) {
    return roumuAxios
      .get('/api/employees', {
        params: _.pickBy(
          {
            _page: query.page,
            employment_status: query.employment_status,
            limit: query.per_page,
            q: query.search_keyword,
            sort: query.sort_column,
            order: query.order,
            embed,
            ...((query.my_number_status === 'not_completed' && {
              exist_my_number: 'false',
            }) ||
              (query.my_number_status === 'completed' && {exist_my_number: 'true'}) ||
              {}),
            ...((query.my_number_employment_status === 'employed_and_joined' && {
              employment_status: ['employed', 'join'],
            }) ||
              (query.my_number_employment_status === 'vacation' && {employment_status: 'vacation'}) ||
              (query.my_number_employment_status === 'unemployed' && {employment_status: 'unemployed'}) ||
              (query.my_number_employment_status === 'all' && {
                employment_status: ['employed', 'vacation', 'join', 'unemployed'],
              }) ||
              {}),
            ...params,
          },
          (value) => value
        ),
      })
      .then((res) => {
        const count = res.headers['x-total-count']
        return {data: res.data, count: count}
      })
  },
  search(query) {
    const {embed = [], only_tm_user = false} = query
    return roumuAxios
      .get('/api/employees', {
        params: {...query, embed: [...embed, 'detail_input_request'], only_tm_user: only_tm_user},
      })
      .then((res) => {
        const count = res.headers['x-total-count']
        const yeaTargetTotalCount = res.headers['yea-target-total-count']
        return {data: res.data, count: count, yeaTargetTotalCount: yeaTargetTotalCount}
      })
  },
  searchAll(query) {
    const {embed = []} = query
    return roumuAxios.get('/api/employees/all', {params: {...query, embed: [...embed]}}).then((res) => {
      return {data: res.data}
    })
  },
  searchDocumentLimitExceeded(query) {
    const {embed = []} = query
    return roumuAxios
      .get('/api/employees/document_limit_exceeded', {
        params: {...query, embed: [...embed]},
      })
      .then((res) => {
        return {data: res.data}
      })
  },
  searchDownloadCount(query) {
    const {embed = [], targetFile = null} = query
    return roumuAxios
      .get('/api/employees/download_count', {
        params: {...query, embed: [...embed], target_file: targetFile},
      })
      .then((res) => {
        return {count: res.data}
      })
  },
  get(id, embed = []) {
    return roumuAxios.get(`/api/employees/${id}?embed=${embed.join(',')}`).then((res) => {
      return {data: res.data}
    })
  },
  async getCurrent(embed = []) {
    const {
      data: {employee},
    } = await roumuAxios.get('/api/me/?embed=employee')
    if (employee) {
      return roumuAxios
        .get(`/api/employees/${employee.id}`, {
          params: {
            embed,
          },
        })
        .then((res) => {
          return {data: res.data}
        })
    } else {
      return {}
    }
  },
  create(params) {
    return roumuAxios.post('/api/employees/', params)
  },
  update(id, params, files = ['icon', 'residence_card']) {
    return postWithFile(formatValues(params), files, roumuAxios.put.bind(roumuAxios, `/api/employees/${id}`))
  },
  delete(id) {
    return roumuAxios.delete(`/api/employees/${id}/`)
  },
  import(params) {
    return postWithFile(params, ['file'], roumuAxios.post.bind(roumuAxios, '/api/import_employees_csv'))
  },
  invite(id, data) {
    return roumuAxios.post(`/api/employees/${id}/invite_to_my_page`, data)
  },
  bulkDelete(ids) {
    return roumuAxios.post('api/employees/bulk_delete', {employee_ids: ids})
  },
  updateResidenceCardFromProcedure(employee_id, procedure_status_id, type, name) {
    return roumuAxios.put(`/api/employees/${employee_id}/update_residence_card_from_procedure`, {
      procedure_status_id: procedure_status_id,
      procedure_tmp_type: type,
      name: name,
    })
  },
  residentCard: {
    get(id) {
      return roumuAxios.get(`/api/employees/${id}/resident_card`).then((res) => ({residentCard: res.data}))
    },
    create(id, params) {
      return roumuAxios.post(`/api/employees/${id}/resident_card`, params)
    },
    delete(id) {
      return roumuAxios.delete(`/api/employees/${id}/resident_card`)
    },
  },
  emergencyContact: {
    get(id) {
      return roumuAxios.get(`/api/employees/${id}/emergency_contact`).then((res) => ({emergencyContact: res.data}))
    },
    create(id, params) {
      return roumuAxios.post(`/api/employees/${id}/emergency_contact`, params)
    },
  },
  healthInsurance: {
    get(id) {
      return roumuAxios.get(`/api/employees/${id}/health_insurance`).then((res) => ({healthInsurance: res.data}))
    },
    create(id, params) {
      return roumuAxios.post(`/api/employees/${id}/health_insurance`, params)
    },
  },
  welfarePensionInsurance: {
    get(id) {
      return roumuAxios
        .get(`/api/employees/${id}/welfare_pension_insurance`)
        .then((res) => ({welfarePensionInsurance: res.data}))
    },
    create(id, params) {
      return postWithFile(
        params,
        ['image'],
        roumuAxios.post.bind(roumuAxios, `/api/employees/${id}/welfare_pension_insurance`)
      )
    },
  },
  employmentInsurance: {
    get(id) {
      return roumuAxios
        .get(`/api/employees/${id}/employment_insurance`)
        .then((res) => ({employmentInsurance: res.data}))
    },
    create(id, params) {
      return postWithFile(
        params,
        ['image'],
        roumuAxios.post.bind(roumuAxios, `/api/employees/${id}/employment_insurance`)
      )
    },
  },
  dependents: {
    list(id) {
      return roumuAxios.get(`/api/employees/${id}/employee_dependents`).then((res) => ({dependents: res.data}))
    },
    create(id, params) {
      return roumuAxios.post(`/api/employees/${id}/employee_dependents`, params)
    },
    update(id, params) {
      return roumuAxios.put(`/api/employees/${id}/employee_dependents/${params.id}`, params)
    },
    delete(id, dependentId) {
      return roumuAxios.delete(`/api/employees/${id}/employee_dependents/${dependentId}`)
    },
    updateAll(id, params, updateOnly = false) {
      return roumuAxios.post(
        `/api/employees/${id}/bulked_employee_dependents`,
        toFormData([
          ...bulkedToFormDataPaires(params, 'employee_dependents', [
            'related_to_relatives_document',
            'related_to_remittance_document',
            'proving_study_abroad_document',
          ]),
          ['update_only', updateOnly],
        ])
      )
    },
    myNumber: {
      get(employeeId, dependentId) {
        return roumuAxios
          .get(`/api/employees/${employeeId}/employee_dependents/${dependentId}/my_number`)
          .then((res) => ({data: res.data}))
      },
      create(employeeId, dependentId, params) {
        return postWithFile(
          params,
          ['my_number_image', 'id_image_0', 'id_image_1'],
          roumuAxios.post.bind(null, `/api/employees/${employeeId}/employee_dependents/${dependentId}/my_number`)
        )
      },
      delete(employeeId, dependentId) {
        return roumuAxios.delete(`/api/employees/${employeeId}/employee_dependents/${dependentId}/my_number`)
      },
    },
  },
  leaveOfAbsences: {
    updateAll(id, params) {
      return roumuAxios.post(`/api/employees/${id}/bulked_employee_leave_of_absences`, {
        employee_leave_of_absences: params,
      })
    },
    updateOrCreateForMaternity(employeeId, startOn, endOn, startOnPrev = null, endOnPrev = null) {
      return roumuAxios.post(`/api/employees/${employeeId}/employee_leave_of_absences/update_or_create_for_maternity`, {
        start_on: startOn,
        end_on: endOn,
        start_on_prev: startOnPrev,
        end_on_prev: endOnPrev,
      })
    },
  },
  contractPeriods: {
    updateAll(id, params) {
      return roumuAxios.post(`/api/employees/${id}/bulked_contract_periods`, {contract_periods: params})
    },
  },
  procedureStatuses: {
    list(id, options) {
      return roumuAxios
        .get(`/api/employees/${id}/procedure_statuses`, {
          params: {
            limit: options.limit,
            not_done: options.notDone,
          },
        })
        .then((res) => {
          const count = res.headers['x-total-count']
          return {
            data: res.data,
            count,
          }
        })
    },
  },
  comments: {
    list(employeeId) {
      return roumuAxios.get(`/api/employees/${employeeId}/comments`).then((res) => ({data: res.data.comments}))
    },

    create(employeeId, params) {
      return roumuAxios.post(`/api/employees/${employeeId}/comments`, {comments: params})
    },

    update(employeeId, commentId, params) {
      return roumuAxios.patch(`/api/employees/${employeeId}/comments/${commentId}`, {comments: params})
    },

    delete(employeeId, commentId) {
      return roumuAxios.delete(`/api/employees/${employeeId}/comments/${commentId}`)
    },
  },
  commutingExpenses: {
    updateAll(id, params) {
      return roumuAxios.post(`/api/employees/${id}/bulked_commuting_expenses`, {commuting_expenses: params})
    },
  },
  resumes: {
    list(employeeId) {
      return roumuAxios.get(`/api/employees/${employeeId}/employee_resumes`).then((res) => ({
        data: res.data,
      }))
    },
    create(employeeId, file) {
      const formData = new FormData()
      formData.append('file', file)
      return roumuAxios.post(`/api/employees/${employeeId}/employee_resumes`, formData)
    },
    delete(employeeId, resumeId) {
      return roumuAxios.delete(`/api/employees/${employeeId}/employee_resumes/${resumeId}`)
    },
  },
  personnelHistories: {
    list(employeeId) {
      return roumuAxios.get(`/api/employees/${employeeId}/bulked_personnel_histories`).then((res) => ({
        data: res.data,
      }))
    },
    updateAll(employeeId, data) {
      return roumuAxios.post(`/api/employees/${employeeId}/bulked_personnel_histories`, {
        personnel_histories: data,
      })
    },
  },
  visaHistories: {
    list(employeeId) {
      return roumuAxios.get(`/api/employees/${employeeId}/bulked_visa_histories`).then((res) => ({
        data: res.data,
      }))
    },
    updateAll(employeeId, data, withFile) {
      return roumuAxios.post(
        `/api/employees/${employeeId}/bulked_visa_histories`,
        withFile
          ? toFormData([
              ...bulkedToFormDataPaires(data.visa_histories, 'visa_histories', ['residence_card']),
              ...(data.employee ? _.map(data.employee, (v, k) => [`employee[${k}]`, v]) : []),
            ])
          : data
      )
    },
    alertOff(employeeId) {
      return roumuAxios.patch(`/api/employees/${employeeId}/visa_alert_button`, {
        has_been_visa_alert_button_pressed: true,
      })
    },
  },
  myNumber: {
    get(employeeId) {
      return roumuAxios.get(`/api/employees/${employeeId}/my_number`).then((res) => ({data: res.data}))
    },
    create(employeeId, params) {
      return postWithFile(
        params,
        ['my_number_image', 'id_image_0', 'id_image_1'],
        roumuAxios.post.bind(null, `/api/employees/${employeeId}/my_number`)
      )
    },
    delete(employeeId) {
      return roumuAxios.delete(`/api/employees/${employeeId}/my_number`)
    },
  },
  customValues: {
    updateAll(employeeId, data) {
      return roumuAxios.post(`/api/employees/${employeeId}/bulked_employees_custom_values`, {
        employees_custom_values: data,
      })
    },
  },
  selections: {
    positions: () => {
      return roumuAxios.get('/api/connected_positions/')
    },
    employmentTypes: () => {
      return roumuAxios.get('/api/connected_employment_types')
    },
    occupations: () => {
      return roumuAxios.get('/api/connected_occupations')
    },
    tags: () => {
      return roumuAxios.get('/api/connected_tags')
    },
    languages: () => {
      return roumuAxios.get('/api/connected_languages')
    },
    languageLevels: () => {
      return roumuAxios.get('/api/connected_language_levels')
    },
    nations: () => {
      return roumuAxios.get('/api/connected_nations')
    },
    countries: () => {
      return roumuAxios.get('/api/connected_countries')
    },
    visaTypes: () => {
      return roumuAxios.get('/api/connected_visa_types')
    },
    prefectures: () => {
      return roumuAxios.get('/api/connected_prefectures')
    },
  },
  csvFormats: {
    availableFields() {
      return roumuAxios.get('/api/export_csv_available_fields').then(({data}) => ({data}))
    },
    list() {
      return roumuAxios.get('/api/export_csv_formats').then(({data}) => ({data}))
    },
    get(id, params) {
      return roumuAxios.get(`/api/export_csv_formats/${id}`, params).then(({data}) => ({data}))
    },
    create(data) {
      return roumuAxios.post('/api/export_csv_formats', data)
    },
    update(id, data) {
      return roumuAxios.put(`/api/export_csv_formats/${id}`, data)
    },
    updateFields(id, data) {
      return roumuAxios.post(`/api/export_csv_formats/${id}/bulked_export_csv_format_fields`, data)
    },
    delete(id) {
      return roumuAxios.delete(`/api/export_csv_formats/${id}`)
    },
  },
  banks: {
    list(params) {
      return roumuAxios.get('/api/banks', {params})
    },
  },
  branches: {
    list(params) {
      return roumuAxios.get('/api/bank_branches', {params})
    },
  },
  detailInputRequest: {
    get(id) {
      return roumuAxios.get(`/api/employees/${id}/detail_input_request`).then((res) => ({data: res.data}))
    },
    create(params) {
      return roumuAxios.post(`/api/employees/detail_input_request`, params)
    },
    reject(id, data) {
      return roumuAxios.post(`/api/employees/${id}/detail_input_request/reject`, data)
    },
    cancel(id) {
      return roumuAxios.post(`/api/employees/${id}/detail_input_request/cancel`)
    },
    accept(id, data) {
      return roumuAxios.post(`/api/employees/${id}/detail_input_request/accept`, data)
    },
  },
  checkNeedEmployeeUpdate: {
    get(id) {
      return roumuAxios.get(`/api/employees/${id}/check_need_employee_updates`).then((res) => ({data: res.data}))
    },
  },
  payroll: {
    import() {
      return roumuAxios.post('/api/jbc/payroll/employees/import')
    },
    updateTargets(data) {
      return roumuAxios.put('/api/jbc/payroll/employees/targets', data)
    },
  },
  yearEndAdj: {
    updateTargets(data) {
      return roumuAxios.put('/api/jbc/yea/employees/targets', data)
    },
  },
  dependentsInSingles: {
    update(id, params) {
      return postWithFile(
        params,
        ['child_support_allowance_file_name'],
        roumuAxios.put.bind(roumuAxios, `/api/employees/${id}/employee_dependents_in_singles`)
      )
    },
    delete(id) {
      return roumuAxios.delete(`/api/employees/${id}/employee_dependents_in_singles`)
    },
  },
  employeeContactInformation: {
    get(id) {
      return roumuAxios
        .get(`/api/employees/${id}/employee_contact_information`)
        .then((res) => ({employeeContactInformation: res.data}))
    },
    update(id, params) {
      return roumuAxios.put(`/api/employees/${id}/employee_contact_information`, params)
    },
  },
  employeeProjects: {
    updateAll(id, params) {
      return roumuAxios.put(`/api/employees/${id}/bulked_employee_projects`, {employee_projects: params})
    },
  },
  employeeSkills: {
    updateAll(id, params) {
      return roumuAxios.put(`/api/employees/${id}/bulked_employee_skills`, {employee_skills: params})
    },
  },
  employeeQualifications: {
    updateAll(id, params, withFile) {
      return roumuAxios.put(
        `/api/employees/${id}/bulked_employee_qualifications`,
        withFile ? toFormData([...bulkedToFormDataPaires(params, 'employee_qualifications', ['certificate'])]) : params
      )
    },
  },
  employeeLanguages: {
    updateAll(id, params, withFile) {
      return roumuAxios.put(
        `/api/employees/${id}/bulked_employee_languages`,
        withFile ? toFormData([...bulkedToFormDataPaires(params, 'employee_languages', ['certificate'])]) : params
      )
    },
  },
  employeeWorkHistories: {
    updateAll(id, params) {
      return roumuAxios.put(`/api/employees/${id}/bulked_employee_work_histories`, {employee_work_histories: params})
    },
  },
  employeeEducationBackgrounds: {
    updateAll(id, params) {
      return roumuAxios.put(`/api/employees/${id}/bulked_employee_education_backgrounds`, {
        employee_education_backgrounds: params,
      })
    },
  },
  employeeTags: {
    updateAll(id, params) {
      return roumuAxios.put(`/api/employees/${id}/bulked_employee_tags`, {
        employee_tags: params,
      })
    },
  },
})
export default employees
