import {gql} from '@apollo/client'

export const EMPLOYEE_DEPENDENTS = gql`
  query employeeDependents($dependentIds: [ID!]) {
    user {
      employee {
        employeeDependentsWhereByIds(dependentIds: $dependentIds) {
          id
          firstName
          lastName
          firstNameKana
          lastNameKana
          birthday
          gender
          relationType
          relationReportType
          relationName
          residenceStatus
          domesticRemittance
          remittance
          prefecture {
            id
          }
          postcode0
          postcode1
          city
          houseNumber
          building
          addressKana
          addressForeign
          job
          phoneNumber0
          phoneNumber1
          phoneNumber2
          basicPensionNumber0
          basicPensionNumber1
          nationalType
          romajiName
          romajiNameKana
          dependentReason
          annualIncome
          annualEarnings
          handicapType
          handicapDetail
          dependentTaxLaw
          isNonResident
          isStudyAbroad
          relatedToRelativesDocument
          relatedToRemittanceDocument
          provingStudyAbroadDocument
        }
      }
    }
  }
`

export interface EmployeeDependentVars {
  dependentIds: string[]
}

export interface EmployeeDependentData {
  client: {
    employee: EmployeeDependent[]
  }
}

interface Prefecture {
  id: string
}

interface File {
  filename: string
  url: string
}

interface EmployeeDependent {
  id: number | null
  firstName: string | null
  lastName: string | null
  firstNameKana: string | null
  lastNameKana: string | null
  birthday: string | null
  gender: string | null
  relationName: string | null
  relationType: 'spouse' | 'other' | null
  relationReportType: 'parents' | 'child' | 'other' | 'real_child_or_adopted_child' | 'other_child' | null
  residenceStatus: 'same_address' | 'different_address' | 'different_and_foreign_address' | null
  domesticRemittance: number | null
  remittance: number | null
  prefecture: Prefecture | null
  postcode0: string | null
  postcode1: string | null
  city: string | null
  houseNumber: string | null
  building: string | null
  addressKana: string | null
  addressForeign: string | null
  job: string | null
  phoneNumber0: string | null
  phoneNumber1: string | null
  phoneNumber2: string | null
  basicPensionNumber0: string | null
  basicPensionNumber1: string | null
  nationalType: 'japanese' | 'foreigner' | null
  romajiName: string | null
  romajiNameKana: string | null
  dependentReason: string | null
  annualIncome: number | null
  annualEarnings: number | null
  handicapType: 'none' | 'normal' | 'special' | null
  handicapDetail: string | null
  dependentTaxLaw: boolean | null
  isNonResident: boolean | null
  isStudyAbroad: boolean | null
  relatedToRelativesDocument: File | null
  relatedToRemittanceDocument: File | null
  provingStudyAbroadDocument: File | null
}
