import {FC} from 'react'
import {Link} from 'react-router-dom'
import useReactRouter from 'use-react-router'
import {getMainOfficeContactInfo} from 'libs/officeUtils'
import {Header} from 'components/layout/Header'
import styles from './Header.scss'
import {Logo} from './parts/Logo'
import {Help} from './parts/Help'
import {MainOffice} from './parts/MainOffice'
import {OfficeSelect} from './parts/OfficeSelect'
import {UserMenu, MenuItem} from './parts/UserMenu'

import type {User} from 'types/api/users/user'
import type {CurrentClientForAdmin} from 'types/api/clients/currentClient'

interface LoggedInHeaderProps {
  client?: CurrentClientForAdmin
  currentUser: User | Record<string, never>
}

export const LoggedInHeader: FC<LoggedInHeaderProps> = ({client, currentUser = {}}) => {
  const {history} = useReactRouter()
  const name = currentUser.employee?.display_full_name || currentUser.email
  const mainOfficeSocial = getMainOfficeContactInfo(client?.office)
  const clientRole = currentUser.client_role?.client_role_type
  const customerCode = currentUser.customer_code

  const menuItems: MenuItem[] = []
  if (clientRole !== 'member') {
    menuItems.push({
      label: '問い合わせ番号',
      description: customerCode,
      style: styles.customerCodeStyle,
    })
  }
  if (!currentUser.employee) {
    menuItems.push(
      {label: 'アカウント設定', onClick: () => history.push('/account_settings/account')},
      {label: 'ログアウト', onClick: () => history.push('/logout')}
    )
  }

  return (
    <Header.Content>
      <Link to="/dashboard">
        <Logo />
      </Link>
      <Help />
      {mainOfficeSocial?.name && <MainOffice name={mainOfficeSocial.name} linkTo="/client" />}
      <OfficeSelect />
      <UserMenu name={name} menuItems={menuItems} />
    </Header.Content>
  )
}
