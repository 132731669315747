import React from 'react'
import {connect} from 'react-redux'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {Dl, Dd, Dt, Grid, Wrap, Pre} from 'employees/show/Parts'
import {widowTypes, singleParentWidowTypes, workingStudentTypes} from 'employees/form/PersonalType'
import {handicapTypes} from 'FormFields'
import {HeaddingWithLink} from 'employees/show/utils'
import {dependentsInSingleTypes} from '../form/DependentsInSingle'
import styles from 'employees/show/Parts.scss'
import ReadMoreReadLess from 'components/ReadMoreReadLess'

const PersonalType = ({employees, editLink, canEdit, forProfiles}) => (
  <Panel>
    <HeaddingWithLink to={`${editLink}#PersonalType`} title="税区分情報" canEdit={canEdit} />
    <Item>
      {forProfiles ? (
        <Wrap forProfiles>
          <Dl>
            <Dt width={35}>障害者区分</Dt>
            <Dd width={15}>{recordDisplay.option(employees.handicap_type, handicapTypes)}</Dd>
            <div style={{display: 'contents'}}>
              <Dd width={50}>
                <Pre>{recordDisplay(employees.handicap_detail)}</Pre>
              </Dd>
            </div>
          </Dl>
          <Dl>
            <Dt width={35}>勤労学生区分</Dt>
            <Dd width={15}>{recordDisplay.option(employees.working_student_type, workingStudentTypes)}</Dd>
            <div style={{display: 'contents'}}>
              <Dd width={50}>
                <Pre>{recordDisplay(employees.working_student_detail)}</Pre>
              </Dd>
            </div>
          </Dl>
          <Dl>
            <Dt width={35}>ひとり親・寡婦区分</Dt>
            <Dd width={15}>{recordDisplay.option(employees.single_parent_widow_type, singleParentWidowTypes)}</Dd>
            <div style={{display: 'contents'}}>
              <Dd width={50}>
                <Pre>{recordDisplay(employees.single_parent_widow_detail)}</Pre>
              </Dd>
            </div>
          </Dl>
        </Wrap>
      ) : (
        <Grid>
          <Dl>
            <Dt>障害者区分</Dt>
            <Dd>
              <span>{recordDisplay.option(employees.handicap_type, handicapTypes)}</span>
              <ReadMoreReadLess>{recordDisplay(employees.handicap_detail)}</ReadMoreReadLess>
            </Dd>
          </Dl>
          <Dl>
            <Dt>勤労学生区分</Dt>
            <Dd>
              <span>{recordDisplay.option(employees.working_student_type, workingStudentTypes)}</span>
              <ReadMoreReadLess>{recordDisplay(employees.working_student_detail)}</ReadMoreReadLess>
            </Dd>
          </Dl>
          <Dl>
            <Dt>ひとり親・寡婦区分</Dt>
            <Dd>
              <span>{recordDisplay.option(employees.single_parent_widow_type, singleParentWidowTypes)}</span>
              <ReadMoreReadLess>{recordDisplay(employees.single_parent_widow_detail)}</ReadMoreReadLess>
            </Dd>
          </Dl>
          <Dl>
            <Dt>寡婦（夫）区分（2020年以前）</Dt>
            <Dd className={styles.threeColumns}>
              <span>{recordDisplay.option(employees.widow_type, widowTypes)}</span>
              <ReadMoreReadLess>{recordDisplay(employees.widow_detail)}</ReadMoreReadLess>
            </Dd>
          </Dl>
          <Dl>
            <Dt>単身児童扶養者（2020年以前）</Dt>
            <Dd>{recordDisplay.option(employees.dependents_in_single_type, dependentsInSingleTypes)}</Dd>
          </Dl>
        </Grid>
      )}
    </Item>
  </Panel>
)

export default connect((state) => ({
  employees: state.employees.current.data,
}))(PersonalType)
