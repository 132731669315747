import React, {useContext, useRef, useEffect, createContext} from 'react'
import {withRouter} from 'react-router'
import _ from 'lodash'
import {connect} from 'react-redux'
import {fetchSelector} from 'actions'
import ListGroup from 'jbc-front/components/ListGroup'
import {isAdminSelector} from 'utils'
import {AnchorLink} from 'jbc-front/components/ScrollSpy'
import styles from 'employees/Navi.scss'
import {FieldGroupProvider, FieldGroupStore} from 'employees/FieldWithGroup'
import {sortFieldGroupsAll} from 'utils'
import classNames from 'classnames'

const BaseAnchor = ({current, anchorTarget, children}) => (
  <ListGroup.Item
    as={AnchorLink}
    name={anchorTarget}
    style={current === anchorTarget ? {backgroundColor: '#3498DB'} : {backgroundColor: ''}}
  >
    {current === anchorTarget ? (
      <strong className={styles.naviLink}>{children}</strong>
    ) : (
      <span className={styles.naviLink}>{children}</span>
    )}
    {current === anchorTarget && <ListGroup.Icon />}
  </ListGroup.Item>
)

export const AnchorWithLink = withRouter(({location, name}) => {
  const anchorRef = useRef(null)
  useEffect(() => {
    if (location?.hash && anchorRef) {
      const name = location.hash.slice(1)
      if (name === anchorRef.current.getAttribute('name')) {
        anchorRef.current.scrollIntoView()
      }
    }
  }, [])
  return <a name={name} ref={anchorRef} />
})

const AnchorWithPermission = ({anchorTarget, current, names = [], children, customField}) => {
  const {groups} = useContext(FieldGroupStore)
  const isIncludeNames = names.some((name) => _.keys(groups).includes(name))
  return customField || isIncludeNames ? <BaseAnchor {...{anchorTarget, current, children}} /> : null
}

const Anchor = ({anchorTarget, names, label, customField}) => {
  const {current, withPermission} = useContext(CurrentStore)
  const AnchorComponent = withPermission ? AnchorWithPermission : BaseAnchor
  return <AnchorComponent {...{anchorTarget, names, current, customField}}>{label}</AnchorComponent>
}

const CurrentStore = createContext()

const CurrentStoreProvider = ({current, isAdmin, children}) => (
  <CurrentStore.Provider value={{current, withPermission: !isAdmin}}>{children}</CurrentStore.Provider>
)

const BasicInfomation = () => <Anchor anchorTarget="BasicInfomation" names={['BasicInfomation']} label="基本情報" />

const BasicInfomationWithSubPanel = () => (
  <Anchor
    anchorTarget="BasicInfomation"
    names={['BasicInfomation', 'EmergencyContact', 'ResidentCard']}
    label="基本情報"
  />
)

const EmploymentInfomation = () => (
  <Anchor anchorTarget="EmploymentInfomation" names={['EmploymentInfomation']} label="業務情報" />
)

const CurriculumVitae = () => (
  <Anchor anchorTarget="CurriculumVitae" names={['CurriculumVitae']} label="履歴書・職務経歴書" />
)

const EmploymentInfomationWithSubPanel = () => (
  <Anchor anchorTarget="EmploymentInfomation" names={['EmploymentInfomation', 'CurriculumVitae']} label="業務情報" />
)

const RemunerationInfomation = () => (
  <Anchor anchorTarget="RemunerationInfomation" names={['RemunerationInfomation']} label="給与" />
)

const RemunerationInfomationWithSubPanel = () => (
  <Anchor
    anchorTarget="RemunerationInfomation"
    names={['RemunerationInfomation', 'BankInfomation', 'CommutingAllowanceInfomation']}
    label="給与情報"
  />
)

const CommutingAllowanceInfomation = () => (
  <Anchor anchorTarget="CommutingAllowanceInfomation" names={['CommutingAllowanceInfomation']} label="通勤手当" />
)

const BankInfomation = () => <Anchor anchorTarget="BankInfomation" names={['BankInfomation']} label="口座情報" />

const Dependent = () => <Anchor anchorTarget="Dependent" names={['Dependent']} label="扶養情報" />

const Marital = () => <Anchor anchorTarget="Marital" names={['Marital']} label="配偶者情報" />

const EmergencyContact = () => (
  <Anchor anchorTarget="EmergencyContact" names={['EmergencyContact']} label="緊急連絡先" />
)

const ResidentCard = () => <Anchor anchorTarget="ResidentCard" names={['ResidentCard']} label="住民票住所" />

const PersonalType = () => <Anchor anchorTarget="PersonalType" names={['PersonalType']} label="税区分情報" />

const HealthInsuranceInfomation = () => (
  <Anchor anchorTarget="HealthInsuranceInfomation" names={['HealthInsuranceInfomation']} label="健康保険" />
)

const HealthAndWelfarePensionInfomation = () => (
  <Anchor
    anchorTarget="HealthInsuranceInfomation"
    names={['HealthInsuranceInfomation', 'WelfarePensionInsuranceInfomation', 'Dependent']}
    label="社会保険"
  />
)

const WelfarePensionInsuranceInfomation = () => (
  <Anchor
    anchorTarget="WelfarePensionInsuranceInfomation"
    names={['WelfarePensionInsuranceInfomation']}
    label="厚生年金保険"
  />
)

const EmploymentInsuranceInfomation = () => (
  <Anchor anchorTarget="EmploymentInsuranceInfomation" names={['EmploymentInsuranceInfomation']} label="雇用保険" />
)

const EmployeeContactInformation = () => (
  <Anchor anchorTarget="EmployeeContactInformation" names={['EmployeeContactInformation']} label="社内連絡先" />
)

const EmployeeProject = () => <Anchor anchorTarget="EmployeeProject" names={['EmployeeProject']} label="プロジェクト" />

const EmployeeSkill = () => <Anchor anchorTarget="EmployeeSkill" names={['EmployeeSkill']} label="スキル" />

const EmployeeQualification = () => (
  <Anchor anchorTarget="EmployeeQualification" names={['EmployeeQualification']} label="資格情報" />
)

const EmployeeLanguage = () => <Anchor anchorTarget="EmployeeLanguage" names={['EmployeeLanguage']} label="語学情報" />

const EmployeeWorkHistory = () => (
  <Anchor anchorTarget="EmployeeWorkHistory" names={['EmployeeWorkHistory']} label="職歴" />
)

const EmployeeEducationBackground = () => (
  <Anchor anchorTarget="EmployeeEducationBackground" names={['EmployeeEducationBackground']} label="学歴" />
)

const EmployeeTag = () => <Anchor anchorTarget="EmployeeTag" names={['EmployeeTag']} label="タグ" />

const CustomField = ({fieldGroup, fieldsMap}) => {
  const {customGroupIds} = useContext(FieldGroupStore)

  if (customGroupIds !== undefined && !customGroupIds.includes(fieldGroup.id)) {
    return null
  }

  const id = !!fieldGroup && fieldsMap[fieldGroup.id] && fieldGroup.id
  const label = !!fieldGroup && fieldsMap[fieldGroup.id] && fieldGroup.label
  return !!id && <Anchor key={id} anchorTarget={`customFieldGroup${id}`} label={label} customField />
}

const SortFormNavi = ({
  fieldGroupsAll,
  fieldsMap,
  hideEmploymentInfo,
  isShowEmploymentInsurance,
  isShowHealthInsurance,
  isShowWelfarepensionInsurance,
}) => (
  <>
    {fieldGroupsAll &&
      fieldGroupsAll.map((group, index) => {
        switch (group?.code) {
          case 'basic_infomation':
            return <BasicInfomation key={index} />
          case 'employment_infomation':
            return !hideEmploymentInfo && <EmploymentInfomation key={index} />
          case 'curriculum_vitae':
            return <CurriculumVitae key={index} />
          case 'remuneration_infomation':
            return !hideEmploymentInfo && <RemunerationInfomation key={index} />
          case 'commuting_allowance_infomation':
            return <CommutingAllowanceInfomation key={index} />
          case 'bank_infomation':
            return <BankInfomation key={index} />
          case 'dependent':
            return <Dependent key={index} />
          case 'marital':
            return <Marital key={index} />
          case 'emergency_contact':
            return <EmergencyContact key={index} />
          case 'resident_card':
            return <ResidentCard key={index} />
          case 'personal_type':
            return <PersonalType key={index} />
          case 'health_insurance_infomation':
            return isShowHealthInsurance && <HealthInsuranceInfomation key={index} />
          case 'welfare_pension_insurance_infomation':
            return isShowWelfarepensionInsurance && <WelfarePensionInsuranceInfomation key={index} />
          case 'employment_insurance_infomation':
            return isShowEmploymentInsurance && <EmploymentInsuranceInfomation key={index} />
          case 'employee_contact_information':
            return <EmployeeContactInformation key={index} />
          case 'employee_project':
            return <EmployeeProject key={index} />
          case 'employee_skill':
            return <EmployeeSkill key={index} />
          case 'employee_qualification':
            return <EmployeeQualification key={index} />
          case 'employee_language':
            return <EmployeeLanguage key={index} />
          case 'employee_work_history':
            return <EmployeeWorkHistory key={index} />
          case 'employee_education_background':
            return <EmployeeEducationBackground key={index} />
          case 'employee_tag':
            return <EmployeeTag key={index} />
          case undefined:
            return group.key === 'custom_employee_field_group' ? (
              <CustomField key={index} fieldGroup={group} fieldsMap={fieldsMap} />
            ) : null
          default:
            return null
        }
      })}
  </>
)

const SortNavi = ({fieldGroupsAll, fieldsMap}) => (
  <>
    {fieldGroupsAll &&
      fieldGroupsAll.map((group, index) => {
        switch (group?.code) {
          case 'basic_infomation':
            return <BasicInfomationWithSubPanel key={index} />
          case 'employment_infomation':
            return <EmploymentInfomationWithSubPanel key={index} />
          case 'remuneration_infomation':
            return <RemunerationInfomationWithSubPanel key={index} />
          case 'marital':
            return <Marital key={index} />
          case 'personal_type':
            return <PersonalType key={index} />
          case 'health_insurance_infomation':
            return <HealthAndWelfarePensionInfomation key={index} />
          case 'employment_insurance_infomation':
            return <EmploymentInsuranceInfomation key={index} />
          case 'employee_contact_information':
            return <EmployeeContactInformation key={index} />
          case 'employee_project':
            return <EmployeeProject key={index} />
          case 'employee_skill':
            return <EmployeeSkill key={index} />
          case 'employee_qualification':
            return <EmployeeQualification key={index} />
          case 'employee_language':
            return <EmployeeLanguage key={index} />
          case 'employee_work_history':
            return <EmployeeWorkHistory key={index} />
          case 'employee_education_background':
            return <EmployeeEducationBackground key={index} />
          case 'employee_tag':
            return <EmployeeTag key={index} />
          case undefined:
            return group.key === 'custom_employee_field_group' ? (
              <CustomField key={index} fieldGroup={group} fieldsMap={fieldsMap} />
            ) : null
          default:
            return null
        }
      })}
  </>
)

const SideBar = ({children, className}) => {
  if (matchMedia('only screen and (max-width: 736px)').matches) {
    return <div>{children}</div>
  }
  return <div className={className}>{children}</div>
}

const Navi = ({
  current,
  naviSubmit,
  employeeFieldGroups,
  hideEmploymentInfo,
  isAdmin,
  fields = [],
  fieldGroups = [],
  isShowEmploymentInsurance,
  isShowHealthInsurance,
  isShowWelfarepensionInsurance,
  isForm,
  employee,
  className,
  defaultHeight,
}) => {
  const fieldsMap = _.groupBy(fields, 'custom_employee_field_group_id')
  const fieldGroupsAll = employeeFieldGroups && fieldGroups && sortFieldGroupsAll(employeeFieldGroups, fieldGroups)
  return (
    <CurrentStoreProvider {...{current, isAdmin}}>
      <SideBar
        className={classNames(
          isForm ? styles.formNavi : styles.navi,
          !employee && styles.notHaveJobcanHeader,
          className
        )}
      >
        <ListGroup>
          {isForm ? (
            <>
              <div className={defaultHeight ? styles.defaultNaviItems : styles.naviItems}>
                <SortFormNavi
                  fieldGroupsAll={fieldGroupsAll}
                  fieldsMap={fieldsMap}
                  hideEmploymentInfo={hideEmploymentInfo}
                  isShowEmploymentInsurance={isShowEmploymentInsurance}
                  isShowHealthInsurance={isShowHealthInsurance}
                  isShowWelfarepensionInsurance={isShowWelfarepensionInsurance}
                />
                <span />
              </div>
              <ListGroup.Item>{naviSubmit}</ListGroup.Item>
            </>
          ) : (
            <FieldGroupProvider type={!isAdmin && 'infos'} permissionGroups={employeeFieldGroups}>
              <div className={styles.detailNaviItems}>
                <SortNavi fieldGroupsAll={fieldGroupsAll} fieldsMap={fieldsMap} />
              </div>
            </FieldGroupProvider>
          )}
        </ListGroup>
      </SideBar>
    </CurrentStoreProvider>
  )
}

export default connect((state, {isInitialInput, procedureType, selector, isForm}) => {
  const isAdmin = isAdminSelector(state)
  const formFieldConditions = isForm
    ? {
        isShowEmploymentInsurance: isAdmin || selector(state, 'employment_insurance.joined'),
        isShowHealthInsurance:
          isAdmin || (!(isInitialInput || procedureType === 'enroll') && selector(state, 'health_insurance.joined')),
        isShowWelfarepensionInsurance: isAdmin || selector(state, 'welfare_pension_insurance.joined'),
      }
    : {}
  return {
    isAdmin,
    fieldGroups: fetchSelector(state, 'custom_field_groups').data,
    fields: fetchSelector(state, 'custom_fields').data,
    ...formFieldConditions,
    employee: state.session.currentUser.employee,
  }
})(Navi)
