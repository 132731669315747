import React, {FC} from 'react'
import classNames from 'classnames'
import Button from 'jbc-front/components/Button'
import Paginator from 'jbc-front/components/Paginator'
import {FORMAT} from 'consts/format'
import {useKeywordInput} from 'hooks/useKeywordInput'
import styles from './MasterFinder.scss'
import {KeywordSearch} from '../KeywordSearch'

export interface MasterItem {
  key: string
  label: string
  selected?: boolean
}

interface MasterFinderProps {
  className?: string
  placeholder?: string
  searchResults?: MasterItem[]
  currentPage: number
  totalResult?: number
  perPage: number
  multiple?: boolean
  extraItems?: MasterItem[]
  onSearch: (keyword: string | undefined, page: number) => void
  onSelect: (item: MasterItem) => void
  onClear?: () => void
}

export const MasterFinder: FC<MasterFinderProps> = ({
  className,
  placeholder = 'キーワードで検索',
  searchResults,
  currentPage,
  totalResult,
  perPage,
  multiple,
  extraItems,
  onSearch,
  onSelect,
  onClear,
}) => {
  const [keyword, keywordSubject] = useKeywordInput((value) => onSearch(value, 1))

  return (
    <div className={classNames(styles.masterFinder, className)}>
      <div className={styles.search}>
        <KeywordSearch onChange={(value) => keywordSubject.next(value)} placeholder={placeholder} />
        {onClear && (
          <Button className={styles.clearButton} type="button" as="button" widthAuto onClick={onClear}>
            選択を解除する
          </Button>
        )}
      </div>
      <div className={styles.scrollable}>
        <div className={styles.result}>
          {totalResult === undefined ? FORMAT.DEFAULT_STRING : totalResult} 件の検索結果
        </div>
        <ul className={styles.list}>
          {extraItems?.map((item) => (
            <Item key={item.key} item={item} multiple={multiple} onClick={onSelect} />
          ))}
          {searchResults?.map((item) => (
            <Item key={item.key} item={item} multiple={multiple} onClick={onSelect} />
          ))}
        </ul>
      </div>
      {Number(totalResult) > 0 && (
        <div className={styles.pager}>
          <Paginator
            current={currentPage}
            totalResult={totalResult}
            rowsPerPage={perPage}
            pageLinks={7}
            onClick={(page) => onSearch(keyword, page)}
          />
        </div>
      )}
    </div>
  )
}

interface ItemProps {
  item: MasterItem
  multiple?: boolean
  onClick: (item: MasterItem) => void
}

const Item: FC<ItemProps> = ({item, multiple, onClick}) => (
  <li role="presentation" onClick={() => onClick(item)}>
    {multiple ? (
      <>
        <input className="m-checkbox-input" type="checkbox" checked={item.selected} readOnly />
        <span className="m-checkbox-parts">{item.label}</span>
      </>
    ) : (
      <span>{item.label}</span>
    )}
  </li>
)
