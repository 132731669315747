import React from 'react'
import NotificationsSystem from 'reapop'
import theme from 'reapop-theme-wybo'
import styles from 'components/CustomNotifications.scss'
import _ from 'lodash'

const customTheme = _.update(theme, 'notification.className', (classNames) => ({
  ...classNames,
  main: `${classNames.main} ${styles.customNotification}`,
  message: `${classNames.message} u-break-all`,
}))

export default () => <NotificationsSystem theme={customTheme} />
