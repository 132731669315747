import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION, OTHER} from 'procedureStatuses/reportList/common'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import _ from 'lodash'
import ListGroup from 'jbc-front/components/ListGroup'

export const getReportList = function* ({
  procedureStatus,
  healthInsuranceType,
  healthInsuranceJoined,
  welfarePensionInsuranceJoined,
}) {
  const dependents = getRelatedDependents(_.get(procedureStatus, 'employee.employee_dependents'), procedureStatus)
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')
  const other_dependents = dependents.filter((dependent) => dependent.relation_type === 'other')
  if (
    (healthInsuranceType === 'kyokai' && (healthInsuranceJoined || welfarePensionInsuranceJoined)) ||
    (healthInsuranceType === 'other' && healthInsuranceJoined)
  ) {
    yield {
      num: 'r4',
      title:
        healthInsuranceType === 'kyokai'
          ? '健康保険被扶養者(異動)･国民年金第３号被保険者関係届'
          : '健康保険被扶養者（異動）届',
      editable: true,
      type: HEALTH_AND_WELFARE_PENSION,
    }
  }
  if (healthInsuranceJoined) {
    if (healthInsuranceType === 'kyokai') {
      yield {
        num: 'r3',
        title: '健康保険被保険者資格証明書交付申請書',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
    if (healthInsuranceType === 'its') {
      yield {
        num: 'r5',
        title: '被扶養者（異動）届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
      if (spouse) {
        yield {
          num: 'r150',
          title: '被扶養者現況表（配偶者）',
          dependentId: spouse.id,
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
      yield* other_dependents.map((dependent) => ({
        num: 'r150',
        title: `被扶養者現況表（${dependent.relation_other}）`,
        dependentId: dependent.id,
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }))
    }
    if (spouse && welfarePensionInsuranceJoined) {
      if (healthInsuranceType !== 'kyokai') {
        yield {
          num: 'r6',
          title: '国民年金第３号被保険者関係届',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
      if (spouse.national_type === 'foreigner') {
        yield {
          num: 'r56',
          title: '国民年金第３号被保険者ローマ字氏名届',
          editable: true,
          type: HEALTH_AND_WELFARE_PENSION,
        }
      }
    }
  }
  yield {
    num: 'r132',
    title: '給与所得者の扶養控除等（異動）申告書',
    editable: false,
    type: OTHER,
  }
}

const ProcedureInfo = ({procedureStatus}) => (
  <ListGroup>
    <ListGroup.Title>扶養者情報</ListGroup.Title>
    <ListGroup.Item as={Link} to={`/add_dependents/update/${procedureStatus.id}`}>
      扶養者情報の編集
    </ListGroup.Item>
  </ListGroup>
)

export default {getReportList, ProcedureInfo}
