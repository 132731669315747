import React, {FC, useState} from 'react'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import useRouter from 'use-react-router'
import {Redirect} from 'react-router'

import {DOCUMENT_EMPLOYEES, QueryVariables, SortColumn, Order, QueryResult} from './query'
import {CsvDownloadModal} from './parts/CsvDownloadModal'
import {EmployeeList} from './parts/EmployeeList'
import {LinkTab} from './parts/LinkTab'
import {AllPublishModal} from './parts/AllPublishModal'
import {AllUnPublishModal} from './parts/AllUnPublishModal'

import {TitleContainer} from 'components/layout/TitleContainer'
import {Content} from 'components/layout/Content'
import {MainTitle} from 'components/ui/MainTitle'
import {FloatingContainer} from 'components/layout/FloatingContainer'
import {KeywordSearch} from 'components/ui/KeywordSearch'
import {PerPage} from 'components/ui/PerPage'
import {SearchResult} from 'components/ui/SearchResult'

import {Loading} from 'jbc-front/components/presenters/ui/Loading'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Paginator from 'jbc-front/components/Paginator'
import Button from 'jbc-front/components/Button'

import {useSortColumn} from 'hooks/useSort'
import {useQuery} from 'hooks/graphql'
import {useKeywordInput} from 'hooks/useKeywordInput'

import classnames from 'classnames'
import styles from './Presenter.scss'

export const Presenter: FC = () => {
  const [allPublishModalOpen, setAllPublishModalOpen] = useState<boolean>(false)
  const [allUnPublishModalOpen, setAllUnPublishModalOpen] = useState<boolean>(false)

  const {
    match: {params},
  } = useRouter<{documentId: string}>()
  const documentId = params.documentId

  const [variables, setVariables] = useState<QueryVariables>({
    id: Number(documentId),
    per: 100,
    page: 1,
    search: {
      sortOrder: 'desc',
      sortColumn: 'staff_code',
    },
  })

  const handleSort = (order: Order, column: SortColumn) => {
    setVariables((prev) => ({
      ...prev,
      search: {
        ...prev.search,
        sortOrder: order,
        sortColumn: column,
      },
    }))
  }
  const {sort, sortOrder, sortColumn} = useSortColumn<SortColumn>('staff_code', handleSort)

  const handleKeywordInput = (value: string) => {
    setVariables((prev) => ({
      ...prev,
      search: {
        ...prev.search,
        q: value,
      },
    }))
  }
  const [, keywordSubject] = useKeywordInput(handleKeywordInput)

  const {data, error, loading} = useQuery<QueryResult, QueryVariables>(DOCUMENT_EMPLOYEES, {
    fetchPolicy: 'network-only',
    variables: variables,
  })

  if (error) {
    return <Redirect to={WITH_AUTH_ADMIN_PATHS.DOCUMENTS.INDEX} />
  }

  const document = data?.client?.document

  return (
    <>
      <TitleContainer className={styles.withLinkTabTitle}>
        <MainTitle>書類作成</MainTitle>
        <LinkTab id={documentId} />
      </TitleContainer>

      <Content size="xxl">
        <div className={styles.topContentContainer}>
          <div className={styles.topContent}>
            <CsvDownloadModal documentId={documentId} q={variables.search.q} />
          </div>

          {document && (
            <div className={classnames(styles.topContent, styles.result)}>
              <SearchResult
                total={document.searchDocumentEmployees.totalCount}
                limit={variables.per}
                page={variables.page}
              />
              <span>
                【公開済】{document.documentEmployeesPublishedCount}名/{document.documentEmployeesCount}名
              </span>
            </div>
          )}

          <div className={styles.topContent}>
            <KeywordSearch
              placeholder="スタッフコード、氏名、メール"
              onChange={(value) => keywordSubject.next(value)}
            />
          </div>
        </div>

        <Spacer direction="y" size={20} />

        {loading ? (
          <Loading />
        ) : (
          <>
            <EmployeeList
              order={sortOrder}
              sortColumn={sortColumn}
              onSort={sort}
              document={document}
              variables={variables}
            />

            {document && (
              <>
                <div className="m-pager-area">
                  <Paginator
                    current={variables.page}
                    rowsPerPage={variables.per}
                    onClick={(page: number) => setVariables((prev) => ({...prev, page}))}
                    totalResult={document.searchDocumentEmployees?.totalCount}
                  />
                  <PerPage selected={variables.per} onChange={(per) => setVariables((prev) => ({...prev, per}))} />
                </div>

                <FloatingContainer>
                  <Button
                    onClick={() => setAllPublishModalOpen(true)}
                    primary
                    disabled={
                      !document.dynamicDocument.downloadable ||
                      document.searchDocumentEmployees.totalCount === document.documentEmployeesPublishedCount
                    }
                    disabledReason={
                      !document.dynamicDocument.downloadable
                        ? '書類の作成中・更新中は公開・非公開の操作ができません。'
                        : '書類非公開中の従業員が存在しません。'
                    }
                  >
                    一括公開
                  </Button>
                  <Button
                    onClick={() => setAllUnPublishModalOpen(true)}
                    disabled={!document.dynamicDocument.downloadable || document.documentEmployeesPublishedCount === 0}
                    disabledReason={
                      !document.dynamicDocument.downloadable
                        ? '書類の作成中・更新中は公開・非公開の操作ができません。'
                        : '書類公開中の従業員が存在しません。'
                    }
                  >
                    一括非公開
                  </Button>
                </FloatingContainer>

                <AllPublishModal
                  isOpen={allPublishModalOpen}
                  onClose={() => setAllPublishModalOpen(false)}
                  variables={variables}
                  documentEmployeesCount={data.client.document.documentEmployeesCount}
                  documentEmployeesPublishedCount={data.client.document.documentEmployeesPublishedCount}
                />
                <AllUnPublishModal
                  isOpen={allUnPublishModalOpen}
                  onClose={() => setAllUnPublishModalOpen(false)}
                  variables={variables}
                />
              </>
            )}
          </>
        )}
      </Content>
    </>
  )
}
