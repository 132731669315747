import React, {Component} from 'react'
import Form, {isSubmitting} from 'procedures/changeDependents/add/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import {assignTmpFiles, dependentDefaultValue} from 'employees/form/common'
import CommentModal from 'procedures/CommentModal'
import Button from 'jbc-front/components/Button'
import {Redirect} from 'react-router'
import _ from 'lodash'
import {SubmissionError} from 'redux-form'
import {getBaseErrors} from 'libs/errorHandler'
import checkEmployeeConfirm from 'components/checkEmployeeConfirm'
import compose from 'lodash/fp/compose'
import {toFormValues, isAdminSelector} from 'utils'
import {asyncError} from 'store/actions/asyncError'

class Confirm extends Component {
  constructor(props) {
    super(props)
  }

  makeInitialValues() {
    const {tmpData} = this.props
    const {tmpFiles} = this.props

    const addDependents = tmpData.draft_procedure_add_dependents || tmpData.application_procedure_add_dependents

    if (!addDependents) {
      return {dependents: [dependentDefaultValue()]}
    }

    const dependentsFormValues = {
      date_on: addDependents.date_on,
      dependents: addDependents.employee_dependents || [dependentDefaultValue()],
      ...toFormValues(addDependents),
    }

    return assignTmpFiles({...dependentsFormValues}, tmpFiles)
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
    } = this.props
    this.props.loadProcedureStatus(id, this.props.token)
    this.props.loadTmpData(id, this.props.token)
    this.props.loadComments(id, this.props.token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      submitting,
      match: {
        params: {id},
      },
      procedureStatus,
      healthInsuranceType,
      isAdmin,
      comments,
    } = this.props
    if (procedureStatus.status && procedureStatus.status !== 'applying') {
      return <Redirect to={`/procedure_statuses/${id}`} />
    }
    return (
      <div>
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType="changeDependents">
          {({showModal}) => (
            <Form
              onSubmit={this.handleSubmit}
              initialValues={this.makeInitialValues()}
              otherButtons={
                <Button disabled={submitting} onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
              submitText={healthInsuranceType === 'its' ? '次へ' : '承認して次へ'}
              employee={procedureStatus.employee}
              comments={comments || {}}
              isAdmin={isAdmin}
            />
          )}
        </CommentModal>
      </div>
    )
  }

  handleSubmit = (values) => {
    const {updateAndCreateProcedure, token, healthInsuranceType, procedureStatus} = this.props
    return updateAndCreateProcedure(procedureStatus, values, token, healthInsuranceType)
  }
}

export default compose(
  checkEmployeeConfirm({procetureType: 'add_dependents'}),
  connect(
    (state) => ({
      healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
      tmpData: state.procedureStatuses.tmpData.data,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      comments: fetchSelector(state, 'procedure_comments').data,
      procedureStatus: state.procedureStatuses.current.data,
      submitting: isSubmitting(state),
      isAdmin: isAdminSelector(state),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadProcedureStatus(id, token) {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api.createWithAuth(token).procedureStatuses.get(id, ['employee', 'health_insurance', 'office'])
          )
        )
      },
      loadTmpData(id, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(id, {name: 'procedure_add_dependents'}),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(id, 'application'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndCreateProcedure: async (
        procedureStatus,
        {date_on: dateOn, dependents, ...rest},
        token,
        healthInsuranceType
      ) => {
        const authedApi = api.createWithAuth(token)
        try {
          await authedApi.procedureStatuses.tmpData.updateJson(
            procedureStatus.id,
            'draft',
            'procedure_add_dependents',
            (data = {employee_dependents: [], report_params_sets: {}}) => ({
              ...data,
              date_on: dateOn,
              employee_dependents: dependents.map((dependent) => ({...dependent, dependent_from: dateOn})),
              ...rest,
            })
          )
          await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: dateOn})

          if (healthInsuranceType === 'its' && _.get(procedureStatus, 'employee.health_insurance.joined')) {
            dispatch(push(`/add_dependents/confirm_dependents/${procedureStatus.id}?dependent_index=0`))
          } else {
            try {
              await authedApi.procedureStatuses.accept(procedureStatus.id)
            } catch (err) {
              if (!getBaseErrors(_.get(err, 'response.data')).next().done) {
                throw err
              }
              if (err.response && err.response.status == 422) {
                if (_.get(err.response, 'data.employee_dependents')) {
                  const dependents = err.response.data.employee_dependents
                  const index = dependents.findIndex((dependent) => dependent._errors)
                  if (index >= 0) {
                    const errors = _.mapValues(dependents[index]._errors, (value) =>
                      _.isArray(value) ? value[0] || '' : value
                    )
                    const dependentsErrors = []
                    dependentsErrors[index] = errors
                    throw new SubmissionError({dependents: dependentsErrors})
                  }
                }
              }
              throw err
            }
            dispatch(push(`/add_dependents/flow/${procedureStatus.id}`))
          }
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(Confirm)
