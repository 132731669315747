import React, {Component} from 'react'
import {connect} from 'react-redux'
import NaviForm from 'employees/NaviForm'
import {formName, withFormSelectors, withFormSelectorsProvider} from 'employees/form/common'
import {makeFormValues} from 'employees/Form'
import {replace} from 'connected-react-router'
import {actionCreators} from 'actions'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {employeeDetailEmbed} from 'employees/Show'
import {fetchSelector} from 'actions'
import _ from 'lodash'
import {getState, isAdminSelector} from 'utils'
import {formNames, toDbName} from 'procedures/enroll/tmpData'
import {toFormData} from 'api/postWithFile'
import Loading from 'components/Loading'
import {updateEmployeeDetail} from 'employees/Update'
import {FieldGroupProvider} from 'employees/FieldWithGroup'
import {CommentModal} from 'components/modals/CommentModal'
import CancelModal from 'accountSettings/CancelModal'
import {formatValues} from 'employees/utils'
import {getFormValues} from 'redux-form'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const makeFileData = (form, file, dbName) => {
  if (file instanceof File) {
    form.push([`files[${dbName}]`, file])
  } else if (!file) {
    form.push([`files[${dbName}]`, 'removed'])
  }
}

export const makeFormData = (values, customFields, {type, comment}) => {
  const form = []
  const fileFields = customFields.filter((field) => field.field_type === 'file')
  let data = _.omit(formatValues(values), 'resumes')
  ;[...formNames, ...fileFields.map((field) => `custom_fields.field_${field.id}`)].forEach((fileField) => {
    if (_.get(values, fileField) !== null) {
      data = _.omit(data, fileField)
    }
  })

  form.push(['data', JSON.stringify(data)])

  for (const formName of formNames) {
    makeFileData(form, _.get(values, formName), toDbName(formName))
  }

  // 国外扶養親族関係書類
  for (const dependent of values.dependents) {
    const index = values.dependents.indexOf(dependent)
    // 親族関係書類
    makeFileData(form, dependent.related_to_relatives_document, `dependents.${index}.related_to_relatives_document`)

    // 送金関係書類
    makeFileData(form, dependent.related_to_remittance_document, `dependents.${index}.related_to_remittance_document`)

    // 留学証明書類
    makeFileData(form, dependent.proving_study_abroad_document, `dependents.${index}.proving_study_abroad_document`)
  }

  // 資格情報の証明書画像
  for (const qualification of values.employee_qualifications) {
    const index = values.employee_qualifications.indexOf(qualification)
    makeFileData(form, qualification.certificate, `employee_qualifications.${index}.certificate`)
  }

  // 語学情報の証明書画像
  for (const language of values.employee_languages) {
    const index = values.employee_languages.indexOf(language)
    makeFileData(form, language.certificate, `employee_languages.${index}.certificate`)
  }

  for (const field of fileFields.filter(
    (field) => field.custom_employee_field_permissions[0].permission === 'updatable'
  )) {
    makeFileData(form, _.get(values, `custom_fields.field_${field.id}`), `employees_custom_values.file.${field.id}`)
  }

  form.push(['type', type])

  if (comment) {
    form.push(['comment', comment])
  }

  return toFormData(form)
}

class InitialInput extends Component {
  componentDidMount() {
    const {loadData, loadFieldGroup, token, client} = this.props
    loadData(token)
    loadFieldGroup(token, client)
  }

  handleSubmit = async (comment) => {
    const {data, fields, token, update, getFormValues} = this.props
    const values = await getFormValues(formName)
    const formValues = makeFormValues({values, hideEmploymentInfo: true})
    const type = _.get(data, 'detail_input_request.type')
    await update(formValues, token, fields, type, comment)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {data, submit, submitting, inputData, fieldGroups, employeeFieldGroups, selector, isAdmin} = this.props
    if (_.isEmpty(data) || inputData.loading || (!inputData.data && inputData.finished === undefined)) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <Loading />
          </div>
        </div>
      )
    }
    if (inputData.finished) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">すでに入力済です</p>
          </div>
        </div>
      )
    }
    const {type, status} = _.get(data, 'detail_input_request')
    const comments = _.get(data, 'detail_input_request.comments')
    const isRejectedProfile = type === 'UpdateProfile' && status === 'rejected'
    const modalTitle = isAdmin ? '修正依頼' : 'その他申請'
    const placeholder = isAdmin ? '修正して欲しい内容など入力してください' : ''

    return (
      <FieldGroupProvider
        type={type}
        requestGroups={_.get(data, 'detail_input_request.groups')}
        permissionGroups={employeeFieldGroups}
      >
        <CommentModal
          modalTitle={modalTitle}
          placeholder={placeholder}
          onSubmit={(formData) => this.handleSubmit(formData.comment)}
        >
          {(showModal, _isOpen) => (
            <>
              <NaviForm
                data={data}
                fieldGroups={fieldGroups}
                employeeFieldGroups={employeeFieldGroups}
                onSubmit={showModal}
                submitText={isRejectedProfile ? '修正完了' : '完了'}
                hideEmploymentInfo
                tmpData={inputData.data.data_raw}
                tmpFiles={inputData.data.detail_input_files}
                selector={selector}
                naviSubmit={
                  <Button primary disabled={submitting} onClick={() => submit()} widthWide>
                    {isRejectedProfile ? '修正完了' : '完了'}
                  </Button>
                }
                otherButtons={
                  isRejectedProfile && (
                    <CancelModal
                      id={_.get(data, 'id')}
                      render={({showModal}) => (
                        <Button onClick={showModal} className="u-mr20">
                          依頼を取り下げる
                        </Button>
                      )}
                    />
                  )
                }
                isInitialInput={type === 'InitialInput'}
                comments={comments}
              />
            </>
          )}
        </CommentModal>
      </FieldGroupProvider>
    )
  }
}

const mapStateToProps = (state, {isSubmitting}) => ({
  data: state.employees.current.data,
  submitting: isSubmitting(state),
  fields: fetchSelector(state, 'custom_fields').data,
  inputData: fetchSelector(state, 'detail_input_data'),
  token: state.auth.token,
  nations: state.master.nations,
  fieldGroups: fetchSelector(state, 'custom_field_groups').data,
  employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
  client: state.client.current,
  isAdmin: isAdminSelector(state),
})

const mapDispatchToProps = (dispatch, {submit, getValues}) => ({
  async loadData(token) {
    dispatch(
      actionCreators.employees.current.fetchData(
        api.createWithAuth(token).employees.getCurrent(employeeDetailEmbed(true))
      )
    )
    dispatch(actionCreators.fetchData('detail_input_data', api.createWithAuth(token).detailInputData.get()))
  },
  async loadFieldGroup(token, client) {
    dispatch(actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list()))
    dispatch(
      actionCreators.fetchData(
        'custom_field_groups',
        api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
      )
    )
    dispatch(
      actionCreators.fetchData(
        'custom_fields',
        api.createWithAuth(token).employeeCustom.fields.list(client.id, {
          embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
        })
      )
    )
  },
  destroy() {
    dispatch(actionCreators.employees.current.destroy())
    dispatch(actionCreators.fetchDestroy('detail_input_data'))
    dispatch(actionCreators.fetchDestroy('employee_field_groups'))
    dispatch(actionCreators.fetchDestroy('custom_field_groups'))
    dispatch(actionCreators.fetchDestroy('custom_fields'))
  },
  submit() {
    dispatch(submit)
  },
  async update(values, token, fields, type, comment) {
    try {
      const state = await getState(dispatch)
      const formValues = getValues(state)
      if (type === 'InitialInput') {
        await updateEmployeeDetail(values.employee.id, {resumes: values.resumes}, token, undefined, fields)
      }
      await api
        .createWithAuth(token)
        .detailInputData.create(makeFormData(formValues, fields, {type: _.snakeCase(type), comment}), {
          headers: {'content-type': 'multipart/form-data'},
        })
      dispatch(replace('/dashboard'))
      dispatch(notifySuccess(type === 'InitialInput' ? '初回入力完了しました' : '送信しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
  async getFormValues(formName) {
    const state = await getState(dispatch)
    const getValues = getFormValues(formName)
    return getValues(state)
  },
})

export default InitialInput
  |> connect(mapStateToProps, mapDispatchToProps)
  |> withFormSelectors
  |> withFormSelectorsProvider
