import {useDispatch} from 'react-redux'

// @ts-ignore
import {addNotification} from 'reapop'

type Status = 'default' | 'info' | 'success' | 'warning' | 'error'

const notify = (message: string, status: Status = 'success') =>
  addNotification({
    message: `${message}`,
    position: 'tc',
    status,
    dismissAfter: 3000,
  })

export const useNotify = () => {
  const dispatch = useDispatch()
  return (message: string, status: Status) => dispatch(notify(message, status))
}
