import _ from 'lodash'
import {formatValues} from 'employees/utils'

export const formNames = [
  'icon',
  'welfare_pension_insurance.image',
  'employment_insurance.image',
  'visa_history.residence_card',
  'employee_dependents_in_single.child_support_allowance_file_name',
]

export const toDbName = (name) => (name.match(/^.+\..+/) ? name : `employee.${name}`)

export const toFormName = (name) => {
  const m = name.match(/employees_custom_values\.file\.(.*)/)
  if (m) {
    return `custom_fields.field_${m[1]}`
  }
  return name.replace(/^employee\./, '')
}

const sendFile = async ({file, dbName, authedApi, procedureStatusId, procedureTmpType, tmpFiles}) => {
  if (file && file instanceof File) {
    await authedApi.procedureStatuses.tmpFiles.createOrUpdate(procedureStatusId, file, procedureTmpType, dbName)
  } else if (!file) {
    const tmpFile = tmpFiles.find((tmpFile) => tmpFile.name === dbName)
    if (tmpFile) {
      await authedApi.procedureStatuses.tmpFiles.delete(procedureStatusId, tmpFile.id)
    }
  }
}

// 国外扶養親族関係書類(親族関係書類, 送金関係書類, 留学証明書類)を一時保存
export const saveWithDependentFiles = async ({
  authedApi,
  procedureStatusId,
  dependents,
  procedureTmpType,
  tmpFiles,
}) => {
  for (const dependent of dependents) {
    const index = dependents.indexOf(dependent)

    // 親族関係書類
    await sendFile({
      file: dependent.related_to_relatives_document,
      dbName: `dependents.${index}.related_to_relatives_document`,
      authedApi,
      procedureStatusId,
      procedureTmpType,
      tmpFiles,
    })

    // 送金関係書類
    await sendFile({
      file: dependent.related_to_remittance_document,
      dbName: `dependents.${index}.related_to_remittance_document`,
      authedApi,
      procedureStatusId,
      procedureTmpType,
      tmpFiles,
    })

    // 留学証明書類
    await sendFile({
      file: dependent.proving_study_abroad_document,
      dbName: `dependents.${index}.proving_study_abroad_document`,
      authedApi,
      procedureStatusId,
      procedureTmpType,
      tmpFiles,
    })
  }
}

export const saveWithFiles = async (authedApi, procedureStatusId, values, procedureTmpType, tmpFiles, customFields) => {
  const fileFields = customFields.filter((field) => field.field_type === 'file')
  let tmpData = _.omit(formatValues(values), 'resumes')
  ;[...formNames, ...fileFields.map((field) => `custom_fields.field_${field.id}`)].forEach((fileField) => {
    if (_.get(tmpData, fileField) !== null) {
      tmpData = _.omit(tmpData, fileField)
    }
  })

  await authedApi.procedureStatuses.tmpData.createOrUpdate(procedureStatusId, tmpData, procedureTmpType, 'employee')

  for (const formName of formNames) {
    const dbName = toDbName(formName)
    const file = _.get(values, formName)
    await sendFile({file, dbName, authedApi, procedureStatusId, procedureTmpType, tmpFiles})
  }

  await saveWithDependentFiles({
    authedApi,
    procedureStatusId,
    dependents: values.dependents,
    procedureTmpType,
    tmpFiles,
  })

  // 資格情報の証明書画像
  for (const qualification of values.employee_qualifications) {
    await sendFile({
      file: qualification.certificate,
      dbName: `employee_qualifications.${values.employee_qualifications.indexOf(qualification)}.certificate`,
      authedApi,
      procedureStatusId,
      procedureTmpType,
      tmpFiles,
    })
  }

  // 語学情報の証明書画像
  for (const language of values.employee_languages) {
    await sendFile({
      file: language.certificate,
      dbName: `employee_languages.${values.employee_languages.indexOf(language)}.certificate`,
      authedApi,
      procedureStatusId,
      procedureTmpType,
      tmpFiles,
    })
  }

  for (const field of fileFields.filter(
    (field) => field.custom_employee_field_permissions[0].permission === 'updatable'
  )) {
    const file = _.get(values, `custom_fields.field_${field.id}`)
    const dbName = `employees_custom_values.file.${field.id}`
    await sendFile({file, dbName, authedApi, procedureStatusId, procedureTmpType, tmpFiles})
  }
}
