import React, {useEffect, useState, createContext, useContext} from 'react'
import _ from 'lodash'
import {set} from 'lodash/fp'
import compose from 'lodash/fp/compose'
import {
  formValueSelector,
  getFormValues,
  isPristine as isPristineForm,
  isSubmitting as isSubmittingForm,
  submit as submitForm,
  autofill as autofillForm,
  getFormInitialValues,
} from 'redux-form'
import uuid from 'uuid/v1'
import {toColor} from 'settings/employeeCustom/ColorSelect'
import styles from 'employees/form/common.scss'
import {toFormName} from 'procedures/enroll/tmpData'
import {toFormValues, addDay} from 'utils'

export const formName = 'employeeForm'
export const JAPAN_VALUE = 392

export const dependentDefaultValue = (procedureType) => ({
  residence_status: 'same_address',
  _id: uuid(),
  dependent_tax_law: true,
  handicap_type: 'none',
  ...(procedureType === 'enroll' ? {dependent_reason: '資格取得による'} : {}),
})

export const assignTmpFiles = (data, tmpFiles) =>
  tmpFiles ? tmpFiles.reduce((result, tmpFile) => set(toFormName(tmpFile.name), tmpFile.file, result), data) : data

const getSerial = (value, key) => _.isString(key) && key.split(/_\d/).length > 1 && !key.match(/field_(.*)/)

const concatSerial = (data) => ({
  ...data,
  ..._.reduce(
    _.pickBy(data, getSerial),
    (acc, value, key) => {
      const concatKey = key.split(/_\d/)[0]
      return {
        ...acc,
        [concatKey]: `${acc[concatKey] || ''}${acc[concatKey] ? '-' : ''}${value}`,
      }
    },
    {}
  ),
})

const omitSerial = (data) => _.omitBy(data, getSerial)

const pickDiff = ({values, tmpData}) => ({
  values,
  tmpData: _.pickBy(tmpData, (value, key) => {
    if (_.isArray(value)) {
      return true
    }
    if (_.isObject(value)) {
      return true
    }
    return value !== values[key]
  }),
})

const serialToOne = ({values, tmpData}) => {
  if (!_.isObject(values)) {
    return {values, tmpData}
  }
  const toOne = compose(omitSerial, concatSerial)
  return {
    values: toOne(values),
    tmpData: toOne(tmpData),
  }
}

const toStringIfBool = (value) => (_.isBoolean(value) ? _.toString(value) : value)

const getDiff = (values, tmpData) => {
  const {values: _values, tmpData: _tmpData} = pickDiff(serialToOne({values, tmpData}))
  return _.mapValues(_tmpData, (tmpValue, key) => {
    if (_.isArray(tmpValue)) {
      return tmpValue.map((item) => {
        const fromArr = _values[key] || []
        const from = fromArr.find((i) => i.id == item.id)
        return getDiff(from || {}, item)
      })
    }
    if (isFileObject(tmpValue) && !_values[key]) {
      // When a new file attached in the empty field by employee.
      return {filename: '未入力'}
    }
    if (_.isObject(tmpValue)) {
      return getDiff(_values[key] || {}, tmpValue)
    }
    if (_.isBoolean(tmpValue)) {
      return toStringIfBool(_values[key] ? true : false)
    }
    return toStringIfBool(_values[key]) || '未入力'
  })
}

const isFileObject = (value) => value?.url && value?.filename

const appendValues = (tmpData, values) => {
  const _tmpData = _.isArray(tmpData) ? [...tmpData] : {...tmpData}
  _.forEach(values, (value, key) => {
    if (key === 'dependents') {
      const addedFields = value.filter(({id: valueId}) =>
        _tmpData[key].every(({id: tmpDataId}) => tmpDataId !== valueId)
      )
      return (_tmpData[key] = [..._tmpData[key], ...addedFields])
    }
    if (key === 'contract_periods' || key === 'employee_leave_of_absences') {
      return (_tmpData[key] = value)
    }
    if (isFileObject(value) && _tmpData[key] === null) {
      // This "null" means the removed file by employee.
      return
    }
    if (_.isArray(value)) {
      const tmpDataArray = _tmpData[key] || []
      return (_tmpData[key] = tmpDataArray.map((tmpValue) => {
        const sameValue = value.find((v) => v.id === tmpValue.id)
        return appendValues(tmpValue, sameValue || {})
      }))
    }
    if (_.isObject(value)) {
      _tmpData[key] = appendValues(_tmpData[key] || {}, value)
    }
    if (!(key in _tmpData)) {
      _tmpData[key] = value
    }
  })
  return _tmpData
}

const setRequiredToId = (record, required_names) => {
  record['id'] = ''
  required_names.map((required_name) => (record['id'] += record[required_name]))
  return record
}

const makeInitialValues = (props) => {
  const tmpData = (_.isString(props.tmpData) && JSON.parse(props.tmpData)) || props.tmpData || {}
  const tmpFiles = props.tmpFiles
  const nations = props.nations || []
  const japan = _.find(nations, (nation) => nation.value === JAPAN_VALUE)
  const japanId = japan ? '' + japan.id : null
  const {procedureType, isAdmin} = props

  if (!props.data || _.isEmpty(props.data)) {
    return assignTmpFiles(
      {
        national_type: 'japanese',
        has_dependent: false,
        dependents: [dependentDefaultValue()],
        country: japanId,
        commuting_expenses: {train: [{}], bus: [{}]},
        handicap_type: 'none',
        widow_type: 'none',
        single_parent_widow_type: 'none',
        working_student_type: 'none',
        dependents_in_single_type: 'none',
        ...tmpData,
      },
      tmpFiles
    )
  }
  const employee = props.data
  const {
    resident_card: residentCard,
    emergency_contact: emergencyContact,
    employment_insurance: employmentInsurance,
    welfare_pension_insurance: welfarePensionInsurance,
    health_insurance: healthInsurance,
    employee_dependents: dependents,
    commuting_expenses: commutingExpenses = [],
    employee_leave_of_absences: leaveOfAbsences = [],
    employee_resumes: employeeResumes = [],
    personnel_history: personnelHistory,
    contract_periods: contractPeriods,
    employees_custom_values: customValues = [],
    visa_history: VisaHistory,
    employee_dependents_in_single: employeeDependentsInSingle,
    employee_contact_information: employeeContactInformation,
    employee_projects: employeeProjects = [],
    employee_skills: employeeSkills = [],
    employee_qualifications: employeeQualifications = [],
    employee_languages: employeeLanguages = [],
    employee_work_histories: employeeWorkHistories = [],
    employee_education_backgrounds: employeeEducationBackgrounds = [],
    employee_tags: employeeTags = [],
  } = employee
  let values = {
    handicap_type: 'none',
    widow_type: 'none',
    single_parent_widow_type: 'none',
    working_student_type: 'none',
    dependents_in_single_type: 'none',
    ...toFormValues(employee),
  }

  values.resident_card = toFormValues(residentCard)
  values.emergency_contact = toFormValues(emergencyContact)

  const defaultEmploymentValue = {
    ...(values.joined_at ? {start_on: values.joined_at} : {}),
    ...(values.retired_at ? {end_on: values.retired_at} : {}),
    joined: true,
  }

  const defaultHealthAndWelfareValue = {
    ...defaultEmploymentValue,
    ...(values.retired_at ? {end_on: addDay(values.retired_at, 1)} : {}),
  }

  values.welfare_pension_insurance = {...defaultHealthAndWelfareValue, ...toFormValues(welfarePensionInsurance)}
  values.health_insurance = {...defaultHealthAndWelfareValue, ...toFormValues(healthInsurance)}
  values.employment_insurance = {...defaultEmploymentValue, ...toFormValues(employmentInsurance)}

  if (!props.hideEmploymentInfo) {
    values.employee_leave_of_absences = leaveOfAbsences.map(toFormValues)
    values.contract_periods = _.isEmpty(contractPeriods) ? [{}] : contractPeriods.map(toFormValues)
  }
  let commutingExpensesObject = _.groupBy(commutingExpenses.map(toFormValues), 'commuting_expense_type')
  if (commutingExpensesObject.car) {
    commutingExpensesObject.car = commutingExpensesObject.car[0]
  }
  if (!commutingExpensesObject.train) {
    commutingExpensesObject.train = [{}]
  }
  if (!commutingExpensesObject.bus) {
    commutingExpensesObject.bus = [{}]
  }
  values.commuting_expenses = commutingExpensesObject

  if (dependents && dependents.length > 0) {
    values.dependents = dependents.map((dependent) =>
      toFormValues({...dependent, ...(procedureType === 'enroll' ? {dependent_reason: '資格取得による'} : {})})
    )
    values.has_dependent = true
  } else {
    values.has_dependent = false
    values.dependents = [dependentDefaultValue(procedureType)]
  }
  if (!values.country && japanId) {
    values.country = japanId
  }
  const resumes = {file: employeeResumes.map(({id, file}) => ({id, ...file}))}
  values.resumes = resumes
  values.personnel_history = personnelHistory
  values.custom_fields = _.fromPairs(
    customValues.map((value) => [`field_${value.custom_employee_field_id}`, value.adjusted_value])
  )
  values.visa_history = VisaHistory
  values._no_visa_history = _.isEmpty(VisaHistory)

  values.use_business_name = employee.business_first_name && employee.business_last_name
  values.employee_dependents_in_single = toFormValues(employeeDependentsInSingle)

  values.employee_contact_information = toFormValues(employeeContactInformation)
  values.employee_projects = employeeProjects.map(toFormValues)
  values.employee_skills = employeeSkills.map(toFormValues)
  values.employee_qualifications = employeeQualifications.map(toFormValues)
  values.employee_languages = employeeLanguages.map(toFormValues)
  values.employee_work_histories = employeeWorkHistories.map(toFormValues)
  values.employee_education_backgrounds = employeeEducationBackgrounds.map(toFormValues)
  values.employee_tags = employeeTags.map(toFormValues)

  if (!_.isEmpty(tmpData) && props.withDiff) {
    const result = assignTmpFiles(appendValues({...tmpData, resumes}, values), tmpFiles)

    const comp_values = _.cloneDeep(values)
    const comp_result = _.cloneDeep(result)

    comp_values.employee_projects.map((record) => setRequiredToId(record, ['project_name']))
    comp_values.employee_skills.map((record) => setRequiredToId(record, ['skill_name']))
    comp_values.employee_qualifications.map((record) => setRequiredToId(record, ['qualification_name']))
    comp_values.employee_languages.map((record) => setRequiredToId(record, ['language_id']))
    comp_values.employee_work_histories.map((record) => setRequiredToId(record, ['company_name']))
    comp_values.employee_education_backgrounds.map((record) =>
      setRequiredToId(record, ['school_type_id', 'school_name'])
    )
    comp_values.employee_tags = {
      name: _.map(comp_values.employee_tags, 'name').sort().join(', '),
    }

    comp_result.employee_projects.map((record) => setRequiredToId(record, ['project_name']))
    comp_result.employee_skills.map((record) => setRequiredToId(record, ['skill_name']))
    comp_result.employee_qualifications.map((record) => setRequiredToId(record, ['qualification_name']))
    comp_result.employee_languages.map((record) => setRequiredToId(record, ['language_id']))
    comp_result.employee_work_histories.map((record) => setRequiredToId(record, ['company_name']))
    comp_result.employee_education_backgrounds.map((record) =>
      setRequiredToId(record, ['school_type_id', 'school_name'])
    )
    comp_result.employee_tags = {
      name: _.map(comp_result.employee_tags, 'name').sort().join(', '),
    }

    const diff = getDiff(comp_values, comp_result)
    if (diff.employee_tags.name && diff.employee_tags.name != '未入力') {
      diff.employee_tags = {name: _.map(values.employee_tags, 'name').join(', ')}
    }

    return {...result, _diff: diff}
  }

  let result = assignTmpFiles(
    {
      ...values,
      ...tmpData,
      custom_fields: {...values.custom_fields, ...(tmpData.custom_fields || {})},
    },
    tmpFiles
  )

  if (!isAdmin) {
    result =
      result
      |> set('health_insurance.joined', values?.health_insurance?.joined)
      |> set('welfare_pension_insurance.joined', values?.welfare_pension_insurance?.joined)
      |> set('employment_insurance.joined', values?.employment_insurance?.joined)
  }
  return result
}

export const FormStore = createContext({
  selector: () => null,
  autofill: () => {},
})

export const FormSelectorsProvider = ({children, formName}) => {
  const selectors = {
    selector: formValueSelector(formName),
    submit: submitForm(formName),
    getValues: getFormValues(formName),
    isPristine: isPristineForm(formName),
    isSubmitting: isSubmittingForm(formName),
    autofill: autofillForm.bind(null, formName),
    getInitialValues: getFormInitialValues(formName),
  }
  return <FormStore.Provider value={{...selectors}}>{children}</FormStore.Provider>
}

export const withFormSelectorsProvider = (WrappedComponent) => (props) =>
  (
    <FormSelectorsProvider formName={formName}>
      <WrappedComponent {...props} />
    </FormSelectorsProvider>
  )

export const withFormSelectors = (WrappedComponent) => (props) => {
  const funcs = useContext(FormStore)
  return <WrappedComponent {...funcs} {...props} />
}

export const useInitialValues = ({data, nations, ...rest}) => {
  const [initialValues, setInitialValues] = useState({})
  useEffect(() => {
    setInitialValues(makeInitialValues({data, nations, ...rest}))
  }, [data, nations])
  return initialValues
}

export const getDiffFromOptions = (options, tmpDataValue) => {
  const type = _.find(options, ({value}) => value === tmpDataValue)
  return _.get(type, 'label') || tmpDataValue
}

export const getDiffFromCheckbox = (value) => {
  if (!value) return
  return value === 'true' ? 'チェック有り' : 'チェック無し'
}

export const getDiffFromFile = (value) => (value?.filename ? value : null)

export const Description = ({description, description_color_by_rgb}) => {
  const style = {color: toColor(description_color_by_rgb)}
  return description ? (
    <div style={style} className={styles.description}>
      {description}
    </div>
  ) : null
}
