import React, {useContext, createContext} from 'react'
import {connect} from 'react-redux'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import styles from 'employees/Show.scss'
import {recordDisplay, toUpperCamel, addDay, sortFieldGroupsAll} from 'utils'
import _ from 'lodash'
import comments from 'components/Comments'
import api from 'api'
import {User} from 'jbc-front/components/icons'
import Master from 'Master'
import {employmentStatuses} from 'employees/form/EmploymentInfomation'
import {getGroupsWithCode} from 'employees/show/EmploymentInformation'
import CustomFields from 'employees/show/CustomFields'
import BankInformation from 'employees/show/BankInformation'
import {remunerationTypeOptions} from 'employees/form/RemunerationInfomation'
import CommutingAllowanceInformation from 'employees/show/CommutingAllowanceInformation'
import Marital from 'employees/show/Marital'
import PersonalType from 'employees/show/PersonalType'
import EmployeeContactInformation from 'employees/show/EmployeeContactInformation'
import EmployeeProject from 'employees/show/EmployeeProject'
import EmployeeSkill from 'employees/show/EmployeeSkill'
import EmployeeQualification from 'employees/show/EmployeeQualification'
import EmployeeLanguage from 'employees/show/EmployeeLanguage'
import EmployeeWorkHistory from 'employees/show/EmployeeWorkHistory'
import EmployeeEducationBackground from 'employees/show/EmployeeEducationBackground'
import EmployeeTag from 'employees/show/EmployeeTag'
import {HeaddingWithLink} from 'employees/show/utils'
import {initialGroups, switchGroups} from 'employees/FieldWithGroup'
import {Dl, Dd, Dt, Half, SubPanel, Wrap, ThirdPanel} from 'employees/show/PartsEmployeeSide'
import {AnchorTarget} from 'jbc-front/components/ScrollSpy'
import classNames from 'classnames'

const masters = ['nations', 'languages', 'languageLevels', 'schoolTypes']
const Comments = comments('employee')

const BasicInfomation = ({
  employee,
  editLink,
  showComment,
  currentUser,
  addComment,
  updateComment,
  deleteComment,
  loadComment,
  id,
  token,
  fieldsWithPermission,
  nations,
}) => {
  const emergencyContact = employee.emergency_contact || {}
  const basicInfomationPermissions = fieldsWithPermission['BasicInfomation']
  const emergencyContactPermissions = fieldsWithPermission['EmergencyContact']
  const eesidentCardPermissions = fieldsWithPermission['ResidentCard']
  return (
    <Panel>
      <Master masters={masters} />
      <HeaddingWithLink
        to={`${editLink}#BasicInfomation`}
        title="基本情報"
        canEdit={basicInfomationPermissions.canEdit}
      />
      <Item>
        <div className={styles.basicInfoHead}>
          {employee.icon && employee.icon.url ? (
            <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.profileImg} />
          ) : (
            <span className={styles.profileImg}>
              <User size={78} />
            </span>
          )}
          {showComment && (
            <Comments
              currentUserId={currentUser.id}
              addComment={addComment.bind(null, id, token)}
              updateComment={updateComment.bind(null, id, token)}
              deleteComment={deleteComment.bind(null, id, token)}
              loadComment={loadComment.bind(null, id, token)}
            />
          )}
        </div>
        <Wrap>
          <Half>
            <Dl>
              <Dt>氏名</Dt>
              <Dd>{recordDisplay.fullName(employee, true)}</Dd>
              {employee.national_type === 'foreigner' && employee.romaji_name && (
                <>
                  <Dt>ローマ字氏名</Dt>
                  <Dd>{recordDisplay(employee.romaji_name)}</Dd>
                </>
              )}
              <Dt>生年月日</Dt>
              <Dd>{recordDisplay.birthday(employee)}</Dd>
              <Dt>性別</Dt>
              <Dd>{employee.gender && (employee.gender === 'man' ? '男' : '女')}</Dd>
            </Dl>
          </Half>
          <Half>
            <Dl>
              <Dt>電話番号</Dt>
              <Dd>{recordDisplay.numbers(employee, 'phone_number', 3)}</Dd>
              <Dt width={40}>メールアドレス</Dt>
              <Dd width={60}>{recordDisplay(employee.email)}</Dd>
              <Dt>住所</Dt>
              <Dd>{recordDisplay.address(employee, nations)}</Dd>
            </Dl>
          </Half>
          {emergencyContactPermissions.canShow && (
            <Half>
              <SubPanel>
                <HeaddingWithLink
                  to={`${editLink}#EmergencyContact`}
                  title="緊急連絡先"
                  canEdit={emergencyContactPermissions.canEdit}
                  isSubPanel
                />
                <Dl>
                  <Dt>続柄</Dt>
                  <Dd>{recordDisplay(emergencyContact.relationship)}</Dd>
                  <Dt>氏名</Dt>
                  <Dd>{recordDisplay.fullName(emergencyContact, true)}</Dd>
                  <Dt>電話番号</Dt>
                  <Dd>{recordDisplay.numbers(emergencyContact, 'phone_number', 3)}</Dd>
                  <Dt>住所</Dt>
                  <Dd>{recordDisplay.address(emergencyContact)}</Dd>
                </Dl>
              </SubPanel>
            </Half>
          )}
          {eesidentCardPermissions.canShow && (
            <Half>
              <SubPanel>
                <HeaddingWithLink
                  to={`${editLink}#ResidentCard`}
                  title="住民票住所"
                  canEdit={eesidentCardPermissions.canEdit}
                  isSubPanel
                />
                <Dl>
                  <Dt>住所</Dt>
                  <Dd>{recordDisplay.address(employee.resident_card)}</Dd>
                </Dl>
              </SubPanel>
            </Half>
          )}
        </Wrap>
      </Item>
    </Panel>
  )
}

const EmploymentInfomation = ({isAdmin, editLink, employee, resumes, fieldsWithPermission}) => {
  const employmentInfomationPermissions = fieldsWithPermission['EmploymentInfomation']
  const curriculumVitaePermissions = fieldsWithPermission['CurriculumVitae']
  const personnelHistory = employee?.personnel_history

  return (
    <Panel>
      <HeaddingWithLink
        to={`${editLink}#EmploymentInfomation`}
        title="業務情報"
        canEdit={employmentInfomationPermissions.canEdit}
      />
      <Item>
        {employmentInfomationPermissions.canShow && (
          <table className="m-table-personal">
            <tbody>
              <tr>
                <th>スタッフコード：</th>
                <td>{recordDisplay(employee.staff_code)}</td>
              </tr>
              {isAdmin && (
                <tr>
                  <th>在籍状況：</th>
                  <td>{recordDisplay(employmentStatuses[employee.employment_status])}</td>
                </tr>
              )}
              <tr>
                <th>雇用形態：</th>
                <td>{recordDisplay(personnelHistory?.employment_type_name_with_code)}</td>
              </tr>
              <tr>
                <th>役職：</th>
                <td>{recordDisplay(personnelHistory?.position_name_with_code)}</td>
              </tr>
              <tr>
                <th>グループ：</th>
                <td>
                  {recordDisplay(
                    getGroupsWithCode(personnelHistory).length > 0 &&
                      getGroupsWithCode(personnelHistory).map((group) => (
                        <span key={group.id}>
                          {group}
                          <br />
                        </span>
                      ))
                  )}
                </td>
              </tr>
              <tr>
                <th>職種：</th>
                <td>{recordDisplay(personnelHistory?.occupation_name_with_code)}</td>
              </tr>
              <tr>
                <th>入社日：</th>
                <td>{recordDisplay.date(employee.joined_at)}</td>
              </tr>
              <tr>
                <th>勤続年数：</th>
                <td>{recordDisplay.workingYears(employee)}</td>
              </tr>
              {isAdmin && (
                <tr>
                  <th>基本給：</th>
                  <td>{recordDisplay.yen(employee.remuneration_base)}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {curriculumVitaePermissions.canShow && (
          <SubPanel>
            <HeaddingWithLink
              to={`${editLink}#CurriculumVitae`}
              title="履歴書・職務経歴書"
              canEdit={curriculumVitaePermissions.canEdit}
              isSubPanel
            />
            <Dl>
              {resumes.length > 0 ? (
                resumes.map((resume, index) => (
                  <React.Fragment key={index}>
                    <Dt>ファイル{index + 1}</Dt>
                    <Dd className="u-ta-r">{recordDisplay.file(resume.file)}</Dd>
                  </React.Fragment>
                ))
              ) : (
                <Dd className="u-pl20">{recordDisplay()}</Dd>
              )}
            </Dl>
          </SubPanel>
        )}
      </Item>
    </Panel>
  )
}

const RemunerationInformation = ({employee, editLink, fieldsWithPermission}) => {
  const remunerationInfomationPermissions = fieldsWithPermission['RemunerationInfomation']
  const bankInfomationPermissions = fieldsWithPermission['BankInfomation']
  const commutingAllowanceInfomationPermissions = fieldsWithPermission['CommutingAllowanceInfomation']
  return (
    <Panel>
      <HeaddingWithLink
        to={`${editLink}#RemunerationInfomation`}
        title="給与情報"
        canEdit={remunerationInfomationPermissions.canEdit}
      />
      <Item>
        <Wrap>
          {remunerationInfomationPermissions.canShow && (
            <>
              <Half>
                <Dl>
                  <Dt>給与形態</Dt>
                  <Dd>{recordDisplay.option(employee.remuneration_type, remunerationTypeOptions)}</Dd>
                  <Dt>基本給</Dt>
                  <Dd>{recordDisplay.yen(employee.remuneration_base)}</Dd>
                </Dl>
              </Half>
              <Half>
                <Dl>
                  <Dt width={63}>標準報酬月額（健康保険）</Dt>
                  <Dd width={37}>{recordDisplay.yen(employee.standard_monthly_amount_health)}</Dd>
                  <Dt width={63}>標準報酬月額(厚生年金保険)</Dt>
                  <Dd width={37}>{recordDisplay.yen(employee.standard_monthly_amount_pension)}</Dd>
                </Dl>
              </Half>
            </>
          )}
          {bankInfomationPermissions.canShow && (
            <BankInformation employee={employee} editLink={editLink} canEdit={bankInfomationPermissions.canEdit} />
          )}
          {commutingAllowanceInfomationPermissions.canShow && (
            <CommutingAllowanceInformation
              employee={employee}
              editLink={editLink}
              canEdit={commutingAllowanceInfomationPermissions.canEdit}
              forProfiles
            />
          )}
        </Wrap>
      </Item>
    </Panel>
  )
}

const HealthAndWelfarePensionInformation = ({
  employee,
  editLink,
  healthInsuranceType,
  fieldsWithPermission,
  isAdmin,
}) => {
  const healthInsurance = employee.health_insurance || {}
  const healthInsuranceEndOn = healthInsurance?.end_on || addDay(employee?.retired_at, 1)
  const welfarePensionInsurance = employee.welfare_pension_insurance || {}
  const welfarePensionInsuranceEndOn = welfarePensionInsurance?.end_on || addDay(employee?.retired_at, 1)
  const dependents = employee.employee_dependents || []
  const healthInsurancePermissions = fieldsWithPermission['HealthInsuranceInfomation']
  const welfarePensionInsurancePermissions = fieldsWithPermission['WelfarePensionInsuranceInfomation']
  const dependentPermissions = fieldsWithPermission['Dependent']
  return (
    <Panel>
      <Headding>社会保険</Headding>
      <Item>
        <Wrap>
          {healthInsurancePermissions.canShow && (
            <Half>
              <SubPanel>
                <HeaddingWithLink
                  to={`${editLink}#HealthInsuranceInfomation`}
                  title="健康保険"
                  canEdit={healthInsurancePermissions.canEdit}
                  isSubPanel
                />
                <Dl>
                  <Dt>加入状況</Dt>
                  <Dd>{recordDisplay.boolean(healthInsurance.joined, '加入', '未加入')}</Dd>
                  <Dt width={healthInsuranceType === 'kyokai' ? 56 : 60}>
                    健康保険被保険者整理番号{healthInsuranceType === 'kyokai' && '（年金整理番号）'}
                  </Dt>
                  <Dd width={healthInsuranceType === 'kyokai' ? 44 : 40}>
                    {recordDisplay(healthInsurance.joined && healthInsurance.health_insurance_insured_personnel_number)}
                  </Dd>
                  <Dt>資格取得日</Dt>
                  <Dd>{recordDisplay.date(healthInsurance.joined && healthInsurance.start_on)}</Dd>
                  {isAdmin && (
                    <>
                      <Dt>資格喪失日</Dt>
                      <Dd>{recordDisplay.date(healthInsurance.joined && healthInsuranceEndOn)}</Dd>
                    </>
                  )}
                </Dl>
              </SubPanel>
            </Half>
          )}
          {welfarePensionInsurancePermissions.canShow && (
            <Half>
              <SubPanel>
                <HeaddingWithLink
                  to={`${editLink}#WelfarePensionInsuranceInfomation`}
                  title="厚生年金保険"
                  canEdit={welfarePensionInsurancePermissions.canEdit}
                  isSubPanel
                />
                <Dl>
                  <Dt>加入状況</Dt>
                  <Dd>{recordDisplay.boolean(welfarePensionInsurance.joined, '加入', '未加入')}</Dd>
                  <Dt>基礎年金番号</Dt>
                  <Dd>
                    {recordDisplay.numbers(
                      welfarePensionInsurance.joined && welfarePensionInsurance,
                      'basic_pension_number',
                      2
                    )}
                  </Dd>
                  <Dt>資格取得日</Dt>
                  <Dd>{recordDisplay.date(welfarePensionInsurance.joined && welfarePensionInsurance.start_on)}</Dd>
                  {isAdmin && (
                    <>
                      <Dt>資格喪失日</Dt>
                      <Dd>{recordDisplay.date(welfarePensionInsurance.joined && welfarePensionInsuranceEndOn)}</Dd>
                    </>
                  )}
                </Dl>
              </SubPanel>
            </Half>
          )}
          {dependentPermissions.canShow && (
            <SubPanel>
              <HeaddingWithLink
                to={`${editLink}#Dependent`}
                title="扶養情報"
                canEdit={dependentPermissions.canEdit}
                isSubPanel
              />
              <SubPanel.Item>
                {_.isEmpty(dependents) ? (
                  'なし'
                ) : (
                  <Wrap>
                    {dependents.map((dependent, index) => (
                      <Half key={dependent.id}>
                        <ThirdPanel>
                          <ThirdPanel.Headding>扶養情報 #{index + 1}</ThirdPanel.Headding>
                          <Dl>
                            <Dt>続柄</Dt>
                            <Dd>
                              {dependent.relation_type === 'spouse'
                                ? '配偶者'
                                : recordDisplay(dependent.relation_other)}
                            </Dd>
                            <Dt>氏名</Dt>
                            <Dd>{recordDisplay.fullName(dependent)}</Dd>
                            <Dt>生年月日</Dt>
                            <Dd>{recordDisplay.birthday(dependent)}</Dd>
                            <Dt>職業</Dt>
                            <Dd>{recordDisplay(dependent.job)}</Dd>
                            <Dt width={67}>年間収入（今後1年間見込）</Dt>
                            <Dd width={33}>{recordDisplay.yen(dependent.annual_income)}</Dd>
                            <Dt width={55}>被扶養者になった日</Dt>
                            <Dd width={45}>{recordDisplay.date(dependent.dependent_from)}</Dd>
                          </Dl>
                        </ThirdPanel>
                      </Half>
                    ))}
                    {dependents.length % 2 === 1 && <Half />}
                  </Wrap>
                )}
              </SubPanel.Item>
            </SubPanel>
          )}
        </Wrap>
      </Item>
    </Panel>
  )
}

const EmploymentInsuranceInformation = ({editLink, employmentInsurance, canEdit, isAdmin}) => (
  <Panel key="employment_insurance">
    <HeaddingWithLink to={`${editLink}#EmploymentInsuranceInfomation`} title="雇用保険" canEdit={canEdit} />
    <Item>
      <table className="m-table-personal">
        <tbody>
          <tr>
            <th>加入状況：</th>
            <td>
              {recordDisplay(
                !_.isUndefined(employmentInsurance.joined) && (employmentInsurance.joined ? '加入' : '未加入')
              )}
            </td>
          </tr>
          {employmentInsurance.joined && (
            <tr>
              <th>被保険者番号：</th>
              <td>{recordDisplay.numbers(employmentInsurance, 'person_number', 3)}</td>
            </tr>
          )}
          {employmentInsurance.joined && (
            <tr>
              <th>資格取得日：</th>
              <td>{recordDisplay.date(employmentInsurance.start_on)}</td>
            </tr>
          )}
          {isAdmin && employmentInsurance.joined && (
            <tr>
              <th>離職等年月日：</th>
              <td>{recordDisplay.date(employmentInsurance.end_on)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Item>
  </Panel>
)

const InfosFieldGroupStore = createContext({
  groups: {},
  requestGroups: {},
  isAdmin: false,
  status: '',
})

const FieldGroup = ({fieldNames, anchorTarget, render}) => {
  const {groups, isAdmin, requestGroups, status} = useContext(InfosFieldGroupStore)
  const isRequest = ['none', 'rejected'].includes(status)
  const fieldsWithPermission = _.fromPairs(
    fieldNames.map((name) => {
      const field = groups[name]
      const isUpdatable = field?.permission === 'updatable'
      return [
        name,
        {
          canShow: isAdmin ? true : !_.isEmpty(field),
          canEdit: isAdmin ? true : isRequest ? requestGroups[name] : isUpdatable,
        },
      ]
    })
  )
  return _.some(fieldsWithPermission, ({canShow}) => canShow) ? (
    <>
      <AnchorTarget name={anchorTarget} />
      {render(fieldsWithPermission)}
    </>
  ) : null
}

const InfosFieldGroupProvider = ({permissionGroups, isAdmin, detailInputRequest, children}) => {
  const {groups, status} = detailInputRequest || {}
  const infosPermissionGroups = isAdmin ? initialGroups : switchGroups('infos', null, permissionGroups)
  const requestGroups = groups && _.keyBy(groups, (group) => toUpperCamel(group.code))
  return (
    <InfosFieldGroupStore.Provider
      value={{
        groups: infosPermissionGroups,
        requestGroups,
        isAdmin,
        status,
      }}
    >
      {children}
    </InfosFieldGroupStore.Provider>
  )
}

const Infos = ({
  employee,
  id,
  currentUser,
  nations,
  editLink,
  detailInputRequest,
  fieldGroups,
  employeeFieldGroups,
  token,
  healthInsuranceType,
  ...rest
}) => {
  const employmentInsurance = employee.employment_insurance || {}
  const resumes = employee.employee_resumes || []
  const isAdmin =
    ['full_admin', 'office_admin'].includes(_.get(currentUser, 'client_role.client_role_type')) &&
    currentUser.has_permission_edit_self_profile
  const fieldGroupsAll = employeeFieldGroups && fieldGroups && sortFieldGroupsAll(employeeFieldGroups, fieldGroups)

  return (
    <InfosFieldGroupProvider
      isAdmin={isAdmin}
      permissionGroups={employeeFieldGroups}
      detailInputRequest={detailInputRequest}
    >
      <div className={classNames([styles.fieldWrap, styles.EmployeeScreenSide])}>
        {fieldGroupsAll &&
          fieldGroupsAll.map((group, index) => {
            switch (group.code) {
              case 'basic_infomation':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="BasicInfomation"
                    fieldNames={['BasicInfomation', 'EmergencyContact', 'ResidentCard']}
                    render={(fieldsWithPermission) => (
                      <BasicInfomation
                        {...{
                          employee,
                          editLink,
                          currentUser,
                          id,
                          token,
                          nations,
                          fieldsWithPermission,
                          ...rest,
                        }}
                      />
                    )}
                  />
                )
              case 'employment_infomation':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmploymentInfomation"
                    fieldNames={['EmploymentInfomation', 'CurriculumVitae']}
                    render={(fieldsWithPermission) => (
                      <EmploymentInfomation {...{editLink, employee, resumes, fieldsWithPermission}} />
                    )}
                  />
                )
              case 'remuneration_infomation':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="RemunerationInfomation"
                    fieldNames={['RemunerationInfomation', 'BankInfomation', 'CommutingAllowanceInfomation']}
                    render={(fieldsWithPermission) => (
                      <RemunerationInformation {...{employee, editLink, fieldsWithPermission}} />
                    )}
                  />
                )
              case 'marital':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="Marital"
                    fieldNames={['Marital']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.Marital
                      return <Marital editLink={editLink} canEdit={canEdit} forProfiles />
                    }}
                  />
                )
              case 'personal_type':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="PersonalType"
                    fieldNames={['PersonalType']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.PersonalType
                      return <PersonalType editLink={editLink} canEdit={canEdit} forProfiles />
                    }}
                  />
                )
              case 'health_insurance_infomation':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="HealthInsuranceInfomation"
                    fieldNames={['HealthInsuranceInfomation', 'WelfarePensionInsuranceInfomation', 'Dependent']}
                    render={(fieldsWithPermission) => (
                      <HealthAndWelfarePensionInformation
                        {...{employee, editLink, healthInsuranceType, fieldsWithPermission, isAdmin}}
                      />
                    )}
                  />
                )
              case 'employment_insurance_infomation':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmploymentInsuranceInfomation"
                    fieldNames={['EmploymentInsuranceInfomation']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmploymentInsuranceInfomation
                      return <EmploymentInsuranceInformation {...{editLink, employmentInsurance, canEdit}} />
                    }}
                  />
                )
              case 'employee_contact_information':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeContactInformation"
                    fieldNames={['EmployeeContactInformation']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeContactInformation
                      return <EmployeeContactInformation editLink={editLink} canEdit={canEdit} forProfiles />
                    }}
                  />
                )
              case 'employee_project':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeProject"
                    fieldNames={['EmployeeProject']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeProject
                      return <EmployeeProject editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case 'employee_skill':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeSkill"
                    fieldNames={['EmployeeSkill']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeSkill
                      return <EmployeeSkill editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case 'employee_qualification':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeQualification"
                    fieldNames={['EmployeeQualification']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeQualification
                      return <EmployeeQualification editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case 'employee_language':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeLanguage"
                    fieldNames={['EmployeeLanguage']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeLanguage
                      return <EmployeeLanguage editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case 'employee_work_history':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeWorkHistory"
                    fieldNames={['EmployeeWorkHistory']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeWorkHistory
                      return <EmployeeWorkHistory editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case 'employee_education_background':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeEducationBackground"
                    fieldNames={['EmployeeEducationBackground']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeEducationBackground
                      return <EmployeeEducationBackground editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case 'employee_tag':
                return (
                  <FieldGroup
                    key={index}
                    anchorTarget="EmployeeTag"
                    fieldNames={['EmployeeTag']}
                    render={(fieldsWithPermission) => {
                      const {canEdit} = fieldsWithPermission.EmployeeTag
                      return <EmployeeTag editLink={editLink} canEdit={canEdit} />
                    }}
                  />
                )
              case undefined:
                return group.key === 'custom_employee_field_group' ? (
                  <CustomFields key={index} group={group} addAnchorTarget editLink={editLink} forProfiles />
                ) : null
              default:
                return null
            }
          })}
      </div>
    </InfosFieldGroupProvider>
  )
}

Infos.defaultProps = {
  showComment: true,
  nations: [],
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    token: state.auth.token,
    currentUser: state.session.currentUser,
    nations: state.master.nations,
    healthInsuranceType: _.get(state.employees.current.data, 'office.health_insurance_type'),
  }),
  () => ({
    loadComment(id, token) {
      return api
        .createWithAuth(token)
        .employees.comments.list(id)
        .then(({data: comments}) => comments)
    },
    addComment: (employeeId, token, body) =>
      api
        .createWithAuth(token)
        .employees.comments.create(employeeId, body)
        .then(({data: comment}) => comment),

    updateComment: (employeeId, token, {id, body}) =>
      api
        .createWithAuth(token)
        .employees.comments.update(employeeId, id, body)
        .then(({data: comment}) => comment),

    deleteComment: (employeeId, token, id) => api.createWithAuth(token).employees.comments.delete(employeeId, id),
  })
)(Infos)
