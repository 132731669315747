import React, {useContext, createContext} from 'react'
import _ from 'lodash'
import fp from 'lodash/fp'
import {AnchorTarget} from 'jbc-front/components/ScrollSpy'
import {default as _BasicInfomation} from 'employees/form/BasicInfomation'
import {default as _EmploymentInfomation} from 'employees/form/EmploymentInfomation'
import {default as _Dependents} from 'employees/form/Dependents'
import {default as _EmploymentInsuranceInfomation} from 'employees/form/EmploymentInsuranceInfomation'
import {default as _RemunerationInfomation} from 'employees/form/RemunerationInfomation'
import {default as _BankInfomation} from 'employees/form/BankInfomation'
import {default as _PersonalType} from 'employees/form/PersonalType'
import {default as _WelfarePensionInsuranceInfomation} from 'employees/form/WelfarePensionInsuranceInfomation'
import {default as _HealthInsuranceInfomation} from 'employees/form/HealthInsuranceInfomation'
import {default as _CommutingAllowanceInfomation} from 'employees/form/CommutingAllowanceInfomation'
import {default as _EmergencyContact} from 'employees/form/EmergencyContact'
import {default as _ResidentCard} from 'employees/form/ResidentCard'
import {default as _Marital} from 'employees/form/Marital'
import {default as _CurriculumVitae} from 'employees/form/CurriculumVitae'
import {default as _EmployeeContactInformation} from 'employees/form/EmployeeContactInformation'
import {default as _EmployeeProject} from 'employees/form/EmployeeProject'
import {default as _EmployeeSkill} from 'employees/form/EmployeeSkill'
import {default as _EmployeeQualification} from 'employees/form/EmployeeQualification'
import {default as _EmployeeLanguage} from 'employees/form/EmployeeLanguage'
import {default as _EmployeeWorkHistory} from 'employees/form/EmployeeWorkHistory'
import {default as _EmployeeEducationBackground} from 'employees/form/EmployeeEducationBackground'
import {default as _EmployeeTag} from 'employees/form/EmployeeTag'
import {toUpperCamel} from 'utils'

export const initialGroups = {
  BasicInfomation: true,
  EmploymentInfomation: true,
  CurriculumVitae: true,
  RemunerationInfomation: true,
  CommutingAllowanceInfomation: true,
  BankInfomation: true,
  Dependent: true,
  Marital: true,
  EmergencyContact: true,
  ResidentCard: true,
  PersonalType: true,
  HealthInsuranceInfomation: true,
  WelfarePensionInsuranceInfomation: true,
  EmploymentInsuranceInfomation: true,
  EmployeeContactInformation: true,
  EmployeeProject: true,
  EmployeeSkill: true,
  EmployeeQualification: true,
  EmployeeLanguage: true,
  EmployeeWorkHistory: true,
  EmployeeEducationBackground: true,
  EmployeeTag: true,
}

const mapRequestGroup = (requestGroups = [], permissionGroups = []) => {
  const groups = requestGroups.map((group) => {
    const permissionGroup = _.find(permissionGroups, {code: group.code})
    return {...group, setting: permissionGroup.setting}
  })
  return _.isArray(groups) && groups.length > 0 && _.keyBy(groups, (group) => toUpperCamel(group.code))
}

const mapFieldGroups = (permissionGroups = []) =>
  permissionGroups.length > 0 && permissionGroups
  |> fp.filter((group) => group.permission === 'updatable')
  |> fp.keyBy((group) => toUpperCamel(group.code))

const mapRequestFieldGroups = (permissionGroups = []) =>
  permissionGroups.length > 0 && permissionGroups
  |> fp.filter((group) => group.permission_invitation === 'updatable')
  |> fp.keyBy((group) => toUpperCamel(group.code))

const mapInfosFieldGroups = (permissionGroups = []) =>
  permissionGroups.length > 0 && permissionGroups
  |> fp.filter((g) => g.permission !== 'not_display')
  |> fp.keyBy((group) => toUpperCamel(group.code))

const mapSettingToInitialGroups = (permissionGroups = []) =>
  permissionGroups.length > 0 && initialGroups
  |> ((groups) =>
    _.map(groups, (value, key) => {
      const code = _.snakeCase(key)
      const permissionGroup = _.find(permissionGroups, {code})
      return {code, setting: permissionGroup?.setting}
    }))
  |> fp.keyBy((group) => toUpperCamel(group.code))

export const switchGroups = (type, requestGroups, permissionGroups) => {
  switch (type) {
    case 'DirectRequest': {
      return mapRequestGroup(requestGroups, permissionGroups)
    }
    case 'UpdateProfile': {
      return mapFieldGroups(permissionGroups)
    }
    case 'InitialInput': {
      return mapRequestFieldGroups(permissionGroups)
    }
    case 'enroll': {
      return mapRequestFieldGroups(permissionGroups)
    }
    case 'infos': {
      return mapInfosFieldGroups(permissionGroups)
    }
    default: {
      return mapSettingToInitialGroups(permissionGroups)
    }
  }
}

export const FieldGroupStore = createContext({groups: initialGroups, customGroupIds: undefined})

export const FieldGroupProvider = ({type, requestGroups, requestCustomGroupIds, permissionGroups, children}) => (
  <FieldGroupStore.Provider
    value={{groups: switchGroups(type, requestGroups, permissionGroups), customGroupIds: requestCustomGroupIds}}
  >
    {children}
  </FieldGroupStore.Provider>
)

const withGroups =
  (Field) =>
  ({name, ...props}) => {
    const {groups} = useContext(FieldGroupStore)
    return groups[name] ? (
      <>
        <AnchorTarget name={name} id={name} />
        <Field {...{...props, ...(_.get(groups, `${name}.setting`) || {})}} />
      </>
    ) : null
  }

export const [
  BasicInfomation,
  EmploymentInfomation,
  Dependents,
  EmploymentInsuranceInfomation,
  RemunerationInfomation,
  BankInfomation,
  PersonalType,
  WelfarePensionInsuranceInfomation,
  HealthInsuranceInfomation,
  CommutingAllowanceInfomation,
  EmergencyContact,
  ResidentCard,
  Marital,
  CurriculumVitae,
  EmployeeContactInformation,
  EmployeeProject,
  EmployeeSkill,
  EmployeeQualification,
  EmployeeLanguage,
  EmployeeWorkHistory,
  EmployeeEducationBackground,
  EmployeeTag,
] = [
  _BasicInfomation,
  _EmploymentInfomation,
  _Dependents,
  _EmploymentInsuranceInfomation,
  _RemunerationInfomation,
  _BankInfomation,
  _PersonalType,
  _WelfarePensionInsuranceInfomation,
  _HealthInsuranceInfomation,
  _CommutingAllowanceInfomation,
  _EmergencyContact,
  _ResidentCard,
  _Marital,
  _CurriculumVitae,
  _EmployeeContactInformation,
  _EmployeeProject,
  _EmployeeSkill,
  _EmployeeQualification,
  _EmployeeLanguage,
  _EmployeeWorkHistory,
  _EmployeeEducationBackground,
  _EmployeeTag,
].map(withGroups)
