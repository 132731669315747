import _ from 'lodash'
import {bulkedToFormDataPaires, toFormDataPairs, toFormData} from 'api/postWithFile'

const tm = (roumuAxios) => ({
  clients: {
    getCurrent(embed) {
      return roumuAxios.get('/tm/api/current_client', {params: embed ? {embed} : {}}).then((res) => {
        return {
          current: res.data.current_client,
        }
      })
    },
  },
  employees: {
    search(query) {
      const {embed = [], only_tm_user = false} = query
      return roumuAxios
        .get('/tm/api/employees', {params: {...query, embed: [...embed], only_tm_user: only_tm_user}})
        .then((res) => {
          const count = res.headers['x-total-count']
          return {data: res.data, count: count}
        })
    },
    searchAll(query) {
      const {embed = [], only_tm_user = false} = query
      return roumuAxios
        .get('/tm/api/employees/all', {params: {...query, embed: [...embed], only_tm_user: only_tm_user}})
        .then((res) => {
          return {data: res.data}
        })
    },
    get(id, embed = []) {
      return roumuAxios.get(`/tm/api/employees/${id}?embed=${embed.join(',')}`).then((res) => {
        return {data: res.data}
      })
    },
    selections: {
      positions: () => {
        return roumuAxios.get('/tm/api/connected_positions/')
      },
      employmentTypes: () => {
        return roumuAxios.get('/tm/api/connected_employment_types')
      },
      occupations: () => {
        return roumuAxios.get('/tm/api/connected_occupations')
      },
      employeeStatuses: () => {
        return roumuAxios.get('/tm/api/connected_employee_statuses')
      },
      tags: () => {
        return roumuAxios.get('/tm/api/connected_tags')
      },
      languages: () => {
        return roumuAxios.get('/tm/api/connected_languages')
      },
      languageLevels: () => {
        return roumuAxios.get('/tm/api/connected_language_levels')
      },
      allOffices: () => {
        return roumuAxios.get('/tm/api/connected_offices')
      },
    },
  },
  groups: {
    list() {
      return roumuAxios.get('/tm/api/groups').then(({data}) => ({
        groups: data,
      }))
    },
  },
  tags: {
    list: () => {
      return roumuAxios.get('/tm/api/tags').then(({data}) => ({
        tags: data,
      }))
    },
  },
  languages: {
    list: () => {
      return roumuAxios.get('/tm/api/languages').then(({data}) => ({
        languages: data,
      }))
    },
  },
  languageLevels: {
    list: () => {
      return roumuAxios.get('/tm/api/language_levels').then(({data}) => ({
        languageLevels: data,
      }))
    },
  },
  schoolTypes: {
    list: () => {
      return roumuAxios.get('/tm/api/school_types').then(({data}) => ({
        schoolTypes: data,
      }))
    },
  },
  nations: {
    list: () => {
      return roumuAxios.get('/tm/api/nations').then(({data}) => ({
        nations: data,
      }))
    },
  },
  displaySettings: {
    list() {
      return roumuAxios.get('/tm/api/display_settings').then(({data}) => ({
        displaySettings: data,
      }))
    },
  },
  userDisplaySettings: {
    list(id) {
      return roumuAxios.get(`/tm/api/employees/${id}/talent_user_display_excludes`).then((res) => ({data: res.data}))
    },
    updateAll(id, params) {
      return roumuAxios.post(`/tm/api/employees/${id}/bulked_talent_user_display_excludes`, {
        talent_user_display_excludes: params,
      })
    },
  },
  tmUsers: {
    getCurrent() {
      return roumuAxios.get('/tm/api/me?embed=employee,office')
    },
  },
  notifications: {
    inbox(query) {
      return roumuAxios
        .get('/tm/api/notifications/inbox', {
          params: _.pickBy({_page: query.page, limit: query.per_page}, (value) => value),
        })
        .then((res) => {
          const count = res.headers['x-total-count']
          return {data: res.data, count: count}
        })
    },
    sent(query) {
      return roumuAxios
        .get('/tm/api/notifications/sent', {
          params: _.pickBy({_page: query.page, limit: query.per_page}, (value) => value),
        })
        .then((res) => {
          const count = res.headers['x-total-count']
          return {data: res.data, count: count}
        })
    },
    shared(query) {
      return roumuAxios
        .get('/tm/api/notifications/shared', {
          params: _.pickBy({_page: query.page, limit: query.per_page}, (value) => value),
        })
        .then((res) => {
          const count = res.headers['x-total-count']
          return {data: res.data, count: count}
        })
    },
    status() {
      return roumuAxios.get('/tm/api/notifications/status').then((res) => {
        return {data: res.data}
      })
    },
    get(id) {
      return roumuAxios
        .get(`/tm/api/notifications/${id}`)
        .then((res) => {
          return {data: res.data}
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            return {deleted: true}
          }
          throw err
        })
    },
    create(params) {
      return roumuAxios.post(
        `/tm/api/notifications`,
        toFormData([
          ...[...toFormDataPairs(params.talent_notification, ['file'])].map(([k, v]) => [
            `talent_notification[${k}]`,
            v,
          ]),
          ...bulkedToFormDataPaires(params.talent_notification_options, 'talent_notification_options', ['']),
          ...(params.talent_notification_answers
            ? _.map(params.talent_notification_answers, (v, k) => [`talent_notification_answers[${k}]`, v])
            : []),
        ])
      )
    },
    delete(id) {
      return roumuAxios.delete(`/tm/api/notifications/${id}`)
    },
    answers(id, params) {
      return roumuAxios.get(`/tm/api/notifications/${id}/answers`, {params}).then((res) => {
        const count = res.headers['x-total-count']
        return {data: res.data, count: count}
      })
    },
    getAnswer(id) {
      return roumuAxios
        .get(`/tm/api/notifications/${id}/answer`)
        .then((res) => {
          return {data: res.data}
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            return {deleted: true}
          }
          throw err
        })
    },
    putAnswer(id, params) {
      return roumuAxios.put(`/tm/api/notifications/${id}/answer`, params).then((res) => {
        return {data: res.data}
      })
    },
    remindAnswers(id) {
      return roumuAxios.get(`/tm/api/notifications/${id}/remind_answers`).then((res) => {
        return {data: res.data}
      })
    },
    remind(id) {
      return roumuAxios.post(`/tm/api/notifications/${id}/remind_answers`)
    },
  },
  employeeCustom: {
    fieldGroups: {
      list() {
        return roumuAxios
          .get(`/tm/api/custom_employee_field_groups`)
          .then((res) => ({data: _.sortBy(res.data, 'position')}))
      },
    },
    fields: {
      list() {
        return roumuAxios.get(`/tm/api/custom_employee_fields`).then((res) => ({data: _.sortBy(res.data, 'position')}))
      },
    },
  },
  notificationTemplates: {
    list: () => {
      return roumuAxios.get('/tm/api/notification_templates').then((res) => ({data: res.data}))
    },
    get(id) {
      return roumuAxios.get(`/tm/api/notification_templates/${id}`).then((res) => {
        return {data: res.data}
      })
    },
  },
  roles: {
    get: () => {
      return roumuAxios.get('/tm/api/role').then((res) => ({data: res.data.role}))
    },
  },

  doLogout: () => {
    return roumuAxios.delete('/tm/api/login').then(() => ({}))
  },
})
export default tm
