import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {
  CustomEmployeeFieldGroupForm,
  makeInitialValues,
  formatValues,
} from 'settings/employeeCustom/CustomEmployeeFieldGroup/CustomEmployeeFieldGroupForm'

class FieldGroup extends Component {
  componentDidMount() {
    const {
      token,
      loadFieldGroup,
      client,
      loadFields,
      match: {
        path,
        params: {id},
      },
    } = this.props
    if (path !== '/settings/employee_custom/create') {
      loadFieldGroup(client, id, token)
      loadFields(client, id, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleSubmit = (values) => {
    const {token, updateFieldGroup} = this.props
    return updateFieldGroup(values, token)
  }

  render() {
    const {fieldGroup, fields} = this.props
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">従業員項目カスタマイズ</h1>
        </div>
        <div className="l-wrap-l l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <Link to="/settings/employee_custom" className="l-breadcrumb-link">
              従業員項目カスタマイズ
            </Link>
            <span className="l-breadcrumb-here">編集</span>
          </div>
          <div className="l-wrap-xs">
            <CustomEmployeeFieldGroupForm
              onSubmit={this.handleSubmit}
              initialValues={makeInitialValues(fieldGroup, fields)}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    client: state.client.current,
    fieldGroup: fetchSelector(state, 'custom_field_group').data,
    fields: fetchSelector(state, 'custom_fields_detail').data,
  }),
  (dispatch) => ({
    loadFields(client, fieldGroupId, token) {
      dispatch(
        actionCreators.fetchData(
          'custom_fields_detail',
          api.createWithAuth(token).employeeCustom.fields.list(client.id, {
            embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            custom_employee_field_group_id: fieldGroupId,
          })
        )
      )
    },
    loadFieldGroup(client, fieldGroupId, token) {
      dispatch(
        actionCreators.fetchData(
          'custom_field_group',
          api.createWithAuth(token).employeeCustom.fieldGroups.get(client.id, fieldGroupId)
        )
      )
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('custom_fields_detail'))
      dispatch(actionCreators.fetchDestroy('custom_field_group'))
    },
    async updateFieldGroup(values, token) {
      try {
        const fields = values.custom_employee_fields || []
        if (
          fields.some(
            (field) =>
              ['radio_button', 'pulldown'].includes(field.custom_employee_field.field_type) &&
              _.isEmpty(field.custom_employee_field_options)
          )
        ) {
          dispatch(notifyError('選択肢を追加してください'))
          return
        }
        if (!values.custom_employee_field_group) {
          dispatch(notifyError('項目グループ名を入力してください'))
          return
        }
        await api.createWithAuth(token).employeeCustom.fields.updateAll(formatValues(values))
        dispatch(push('/settings/employee_custom'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(FieldGroup)
