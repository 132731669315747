import React, {useState} from 'react'
import {Section, TextField, CheckboxField, RadioField, DateField, SelectField, Label} from 'jbc-front/components/Form'
import {toFormValues} from 'utils'
import connectForm from 'reportForms/connectForm'
import {reduxForm, FormSection, formValueSelector} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R140Report'

const reasonTypes = [
  {value: 'reminded_more_than_three_times', label: '3回以上督促するも返納に応じないため'},
  {value: 'others', label: 'その他の理由'},
]

const remindedTypes = [
  {value: 'phone', label: '電話'},
  {value: 'email', label: 'メール'},
  {value: 'letter', label: '文書'},
  {value: 'oral', label: '口頭'},
  {value: 'others', label: 'その他の理由'},
]

const repeatNumber = 4

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({handleSubmit, submitting, dependents, submitText, reasonType, remindedTypeValues, employee, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">健康保険被保険者証回収不能届（ITS健保）</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <Label text="回収不能の対象者選択" />
          <CheckboxField name="is_target" label={recordDisplay.fullName(employee)} />

          <LabelMapper name="r140_dependent_param_set" label="扶養情報" />
          {dependents &&
            dependents.map((dependent, index) => (
              <FormSection name={`r140_dependent_param_set[${index}]`} key={dependent.id}>
                <CheckboxField name="is_target" label={recordDisplay.fullName(dependent)} />
              </FormSection>
            ))}
          <div>
            <RadioField name="reason_type" label="回収不能となった理由" options={reasonTypes} />
            {reasonType && reasonType === 'others' && (
              <div>
                <TextField name="reason_detail" label="理由詳細" />
              </div>
            )}
            {_.range(repeatNumber).map((i) => {
              return (
                <div key={i}>
                  <DateField name={`reminded_on_${i}`} label={`被保険者証の返納督促 ${i + 1}回目 実施日`} />
                  <SelectField
                    name={`reminded_type_${i}`}
                    label={`被保険者証の返納督促 ${i + 1}回目 督促方法`}
                    options={remindedTypes}
                  />
                  {remindedTypeValues[i] && remindedTypeValues[i] === 'others' && (
                    <div>
                      <TextField name={`reminded_detail_${i}`} label="理由詳細" />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <DateField name="submit_on" label="書類提出日" />
          <div className="u-ta-c u-mt30">
            <Button primary disabled={submitting} onClick={() => branchSubmit(needUpgrade)}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => handleSubmit()}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})
const makeInitialValues = ({dependents, data: reportValues}) => {
  if (!dependents) {
    return toFormValues(reportValues)
  }
  const r140DependentParamSet = _.get(reportValues, 'r140_dependent_param_set') || []
  const r140DependentValues = dependents.map((dependent) =>
    toFormValues(
      _.find(r140DependentParamSet, (value) => value.employee_dependent_id == dependent.id) || {
        employee_dependent_id: dependent.id,
      }
    )
  )
  return _.assign(toFormValues(reportValues), {r140_dependent_param_set: r140DependentValues})
}
const selector = formValueSelector(formName)
const connectedForm = connectForm('r140', Form, {
  mapState: (state) => ({
    dependents: _.get(state, 'procedureStatuses.current.data.employee.employee_dependents'),
    employee: _.get(state, 'procedureStatuses.current.data.employee') || {},
    reasonType: selector(state, 'reason_type'),
    remindedTypeValues: _.range(repeatNumber).map((index) => selector(state, `reminded_type_${index}`)),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  makeInitialValues,
})

export default connectedForm
